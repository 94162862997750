/**
 * Copyright © Tony Schirmer All rights reserved.
 * See LICENSE.txt for license details.
 */
import {dataServiceConfig} from '../../config';
import md5 from "md5";
import Papa from 'papaparse';

import {addDays, format, formatRFC3339, parseISO} from "date-fns";
import DataServiceResults from "./DataServiceResults";
import download from '../../utils/download'


import DataQuery from "./DataQuery";
import DeviceDataCacheStorage from "./DeviceDataCacheStorage";
// class DeviceLocation {
//
//
//   id: null;
//   name: '';
//   parent_device_location_id: '';
//   updated_at: '';
//   created_at: '';
//
//   constructor(obj) {
//     obj && Object.assign(this, obj);
//   }
//   ToNativeJsObject() : any {
//     return Object.assign({}, this);
//   }
// }
//
// class DeviceLocationList {
//   data: Array<DeviceLocation>;
//   meta: {
//     total: 0,
//     page: 0,
//     page_size: 0
//   };
//
//   ToNativeJsObject() : any {
//     let output = {
//       data: [],
//       meta: {
//         total: 0,
//         page: 0,
//         page_size: 0
//       }
//     };
//     for (let i=0; i< this.data.length; i++) {
//       output.data[i] = Object.assign({}, this.data[i]);
//     }
//     output.meta = Object.assign({}, this.meta);
//     return output;
//   }
// }


const DataServiceActions = {
  add: 'ADD',
  load: 'LOAD',
  load_force: 'LOAD_FORCE',
  reset: 'RESET'
};


// const DataServiceResults = {
//   miss: 'MISS',
//   pass: 'PASS',
//   hit: 'HIT',
// };

const DataServiceMode = {
  inline: 'inline',
  download: 'download',
  stream: 'stream',
};



const formatDate = (date: Date) : string => {
  return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDay()}-${date.getHours()}${date.getMinutes()}${date.getSeconds()}` ;
}

export const CommsUnitStringInput1 = 'comms_unit_input_1';
export const MedianController = 'median_controller';

const apiUrl = dataServiceConfig.url;

export default class DeviceDataService {
  private config: any;
  private cacheStorage: DeviceDataCacheStorage;

  constructor(config, cacheStorage = null) {
    if (config == null) {
      config = {};
    }
    if (cacheStorage == null) {
      cacheStorage = new DeviceDataCacheStorage();
    }
    this.config = config;
    this.cacheStorage = cacheStorage;
  }

  registerControllerSerialNumbersForInstallId(installId, controllerSerialNumbers){
    this.cacheStorage.ControllerSerialNumbersCache[installId] = controllerSerialNumbers
  };

  gatherDaysWithinTimeFrame(startDate, stopDate) {
    let dateArray = [];
    let currentDate = new Date(parseISO(startDate).getTime());
    let endDate =  new Date(parseISO(stopDate).getTime());
    while (currentDate <= endDate) {
      dateArray.push(  format(new Date(currentDate.getTime()), 'YYYYMMDD') )
      currentDate =  addDays(new Date(currentDate.getTime()), 1);
    }
    return dateArray;
  };

  _doesCacheExist (query: DataQuery) {

    if (
        typeof this.cacheStorage.state === 'undefined'
    ){
      console.log('[Cache Miss]: No Data Cached');
      return false;
    }
    let currentChartState = this.cacheStorage.state;

    if (typeof currentChartState[query.dataType] == 'undefined') {
      console.log('[Cache Miss]: ' + query.dataType);
      return false;
    }

    let installId = query.filter.installId;
    if (typeof currentChartState[query.dataType][installId] == 'undefined') {
      console.log('[Cache Miss]: ' + query.dataType + ' ' + installId);
      return false;
    }
    return true;
  };

  _getTimestamps (mergedData, query: DataQuery) {
    // try for a match with all optimisers with time stamps
    //order current cacheStorage by least filled to most filled
    //create timestamps based on least filled, and attempt fit between all curves


    let from = query.filter.from.getTime() / 1000; //unix timestamp in seconds
    let to = query.filter.to.getTime() / 1000;     //unix timestamp in seconds
    // if ((to-from) > query.limit){
    //   for (let i = from; i < to; i++) {
    //     timestampsToDisplay.add(i);
    //   }
    // }

    let nthRecord = Math.ceil((to-from) / query.limit);//to / query.limit;
    let timestampsToDisplay = new Set();
    for (let i = from; i < to; i++) {
      //if (i % query.limit === 0) {

      let mod = i % nthRecord;
      if (mod < 1 && mod > -1) {
        //for (let i = 0; i < to; i++) { //fitting code
        //let mod = i % nthRecord;
        //if (mod < 1 && mod > -1) {
        timestampsToDisplay.add(i);
        //}
      }

    }
    return timestampsToDisplay;
    //
    // let datapointCount = query.limit;
    // let sortedOptimiserIds = _getSortedOptimiserIds(mergedData);
    //
    // let maxAttempts = 3;
    // let attempts = 0;
    //
    // for (let optimiserIdIndex in sortedOptimiserIds){
    //   if (!sortedOptimiserIds.hasOwnProperty(optimiserIdIndex)) {
    //     continue;
    //   }
    //   if (attempts >= maxAttempts){
    //     return false;
    //   }
    //   let optimiserId = sortedOptimiserIds[optimiserIdIndex].optimiserId;
    //   if (optimiserId == null){
    //     continue;
    //   }
    //
    //   //fit timestamps to foundCacheData
    //   let cacheData = mergedData[optimiserId];
    //   let timestampsToDisplay = new Set();
    //   for (let i = 0; i < cacheData.length; i++) {
    //     if (i % datapointCount === 0) {
    //       let nthRecord = cacheData.length / datapointCount;
    //       for (let i = 0; i < cacheData.length; i++) {
    //         let mod = i % nthRecord;
    //         if (mod < 1 && mod > -1) {
    //           timestampsToDisplay.add(cacheData[i].time);
    //         }
    //       }
    //     }
    //   }
    //   let allDatapointsFound = true;
    //   for (let optimiserId in mergedData){
    //     //do all the timestamps exist in the merged data?
    //     let foundTimestampsForOptimiser = true;
    //     for (let timestamp in timestampsToDisplay){
    //       //find in merged data mergedData[optimiserId]
    //       let foundTimestamp = false;
    //       for (let index in mergedData[optimiserId]){
    //         if (mergedData[optimiserId][index].time === timestamp){
    //           foundTimestamp = true;
    //           break;
    //         }
    //       }
    //       if (!foundTimestamp){
    //         foundTimestampsForOptimiser = false;
    //         break;
    //       }
    //     }
    //     if (!foundTimestampsForOptimiser) {
    //       allDatapointsFound = false;
    //       break;
    //     }
    //   }
    //   if (allDatapointsFound === true){
    //     return timestampsToDisplay;
    //   }
    //   attempts++;
    // }
    // return false;

  }
  //
  // get(deviceLocationId): Promise<DeviceLocation> {
  //   let service = this;
  //   const t = (phrase) => { return phrase}; // useTranslation();
  //   return new Promise((resolve, reject) => {
  //     if (! (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(deviceLocationId))){
  //       reject(new Error("id is not a uuid"));
  //     }
  //
  //     let dataUrl = `${apiUrl}/device_location/get`;
  //     fetch(dataUrl, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       credentials: 'include',
  //       body: JSON.stringify({
  //         id: deviceLocationId,
  //       })
  //     })
  //         .then(service.config.afterFetch)
  //         .then((response) => {
  //           switch (response.status) {
  //             case 200:
  //               return response.json();
  //             case 403:
  //               throw new Error(t(phrases.Errors.Unauthorized));
  //             case 404:
  //               reject(new NotFoundError(t('Could not get device location')));
  //               break;
  //             default:
  //               reject( new Error(t('Could not get device location')));
  //               break;
  //           }})
  //         .then(result => {
  //           resolve(new DeviceLocation(result?.data));
  //         })
  //         .catch((error) => {
  //           reject(error);
  //         });
  //   });
  // }
  //
  // save(deviceLocation) {
  //   // remove user from local storage to log user out
  //   let service = this;
  //
  //   const t = (phrase) => { return phrase};
  //   return new Promise((resolve, reject) => {
  //     // if (deviceLocation.id != null  && ! (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(deviceLocation.id))){
  //     //   reject(new Error("id is not a uuid"));
  //     // }
  //     if (deviceLocation?.id === ""){
  //       deviceLocation.id = null;
  //     }
  //
  //     let dataUrl = `${apiUrl}/device_location/save`;
  //     fetch(dataUrl, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       credentials: 'include',
  //       body: JSON.stringify(deviceLocation)
  //       //   id: deviceLocation.id,
  //       //   name: deviceLocation.name,
  //       //   parent_device_deviceLocation_id: deviceLocation.parent_device_location_id,
  //       // })
  //     })
  //         .then(service.config.afterFetch)
  //         .then((response) => {
  //           switch (response.status) {
  //             case 200:
  //               return response.json();
  //             case 403:
  //               throw new Error(t(phrases.Errors.Unauthorized));
  //             default:
  //               throw new Error(t('Could not save device location'));
  //           }})
  //         .then(result => {
  //           if (Object.prototype.hasOwnProperty.call(result, 'error') &&
  //               result.error != null
  //           ) {
  //             console.error(result.error);
  //             reject(result.error);
  //             return;
  //           }
  //           resolve(result);
  //         })
  //         .catch((error) => {
  //           reject(error);
  //         });
  //   });
  // };
  //
  // list(query): Promise<DeviceLocationList> {
  //
  //   let service = this;
  //   const t = (phrase) => { return phrase};
  //   let dataUrl = `${apiUrl}/device_location/list`;
  //   return new Promise((resolve, reject) => {
  //     fetch(dataUrl, {
  //       method: 'POST',
  //       credentials: 'include',
  //       body: JSON.stringify(query)
  //     })
  //         .then(service.config.afterFetch)
  //         .then((response) => {
  //           switch (response.status) {
  //             case 200:
  //               return response.json();
  //             case 403:
  //               throw new Error(t(phrases.Errors.Unauthorized));
  //             default:
  //               throw new Error(t('Could not fetch device locations'));
  //           }
  //         })
  //         .then(function (response) {
  //           // let expectedResponseFields = [
  //           //   'error',
  //           //   'meta',
  //           //   'data'
  //           // ];
  //           //
  //           // let isValidResponse = !expectedResponseFields.map((field) => {
  //           //   return Object.prototype.hasOwnProperty.call(response, field)
  //           // }).includes(false);
  //           //
  //           // if (!isValidResponse){
  //           //   reject(phrases.Errors.UnExpectedResponse);
  //           // }
  //           if (response.hasOwnProperty('error') && response.error != null) {
  //             reject(new Error(t(response.error)));
  //           } else {
  //             //validate response
  //             if (!Array.isArray(response.data)) {
  //               reject(new Error(t(phrases.Errors.UnExpectedResponse)));
  //             }
  //             let output = new DeviceLocationList();
  //             output.data = [];
  //             for (let i = 0; i < response.data.length; i++) {
  //               output.data[i] = new DeviceLocation(response.data[i])
  //             }
  //             output.meta = response.meta;
  //             resolve(output);
  //           }
  //         })
  //         .catch((error) => {
  //           reject(new Error(t(error)));
  //         })
  //
  //   });
  //
  //
  // }
  //
  // delete(deviceLocationId) {
  //
  //   let service = this;
  //   //const {t} = useTranslation();
  //   const t = (phrase) => { return phrase};
  //   return new Promise((resolve, reject) => {
  //
  //     let dataUrl = `${apiUrl}/device_location/delete`;
  //     fetch(dataUrl, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       credentials: 'include',
  //       body: JSON.stringify({
  //         id: deviceLocationId,
  //       })
  //     })
  //         .then(service.config.afterFetch)
  //         .then((response) => {
  //           return response.json()
  //         })
  //         .then(result => {
  //           resolve(result);
  //         })
  //         .catch((error) => {
  //           reject(error);
  //         });
  //   });
  // }
  getTimestamps (mergedData, query: DataQuery) {
    if (typeof query.limit === 'undefined'){
      query.limit = 1200;
    }
    // let timestampCache = {
    //   ...query,
    //   filter: {
    //     ...query.filter,
    //     optimiserIds: ''
    //   }
    // };
    let cachekey = md5(JSON.stringify(query) );

    if (typeof this.cacheStorage.timestampsCache[cachekey] !== 'undefined'){
      return this.cacheStorage.timestampsCache[cachekey];
    }
    let timestamps =  this._getTimestamps(mergedData, query);
    // if (timestamps === false){
    //   return false;
    // }
    this.cacheStorage.timestampsCache[cachekey] = timestamps
    return this.cacheStorage.timestampsCache[cachekey];

  };

  tryFetchDataFromCache (query: DataQuery)  {

    if (!this._doesCacheExist(query)){
      return {data: null, result: DataServiceResults.miss};
    }
    let currentChartState = this.cacheStorage.state;
    let installId = query.filter.installId;

    let datesFound = this.gatherDaysWithinTimeFrame(query.filter.from, query.filter.to);
    if (datesFound.length < 1){
      //bad query, can't serve from cacheStorage
      return {data: null, result: DataServiceResults.miss};
    }
    let mergedData = {};

    let maximumDatapointsInQuery = (query.filter.to.getTime() - query.filter.from.getTime()) / 1000;
    let maximumDatapointsPerDay = maximumDatapointsInQuery / datesFound.length;

    //Minimum Datapoints needed to fulfil the query request with useful data.
    //Will provide a cacheStorage miss if the minimum datapoints aren't available.
    //Will need to fetch that data from the server.
    let minimumDatapointsPerDay = 0;
    if (query.limit > 0) {
      minimumDatapointsPerDay = query.limit / datesFound.length;
    }
    //if the minimum points needed are bigger than the maximum Datapoint available,
    //then the cacheStorage is valid.
    if (minimumDatapointsPerDay > maximumDatapointsPerDay){
      minimumDatapointsPerDay = maximumDatapointsPerDay;
    }

    for (let dateIndex in datesFound) {
      if (!datesFound.hasOwnProperty(dateIndex)) {
        continue;
      }
      let dayCacheKey = datesFound[dateIndex];


      if (typeof currentChartState[query.dataType][installId][dayCacheKey] == 'undefined') {
        console.log('[Cache Miss]: ' + query.dataType + ' '  + installId + ' ' + dayCacheKey);
        return {data: null, result: DataServiceResults.miss};
      }

      let controllerIds = query.filter.controllerIds;
      if (typeof this.cacheStorage.ControllerSerialNumbersCache[installId] !== 'undefined' &&
          query.filter.controllerIds.length < 1 )
      {
        controllerIds = this.cacheStorage.ControllerSerialNumbersCache[installId];
      }



      for (let controllerIdIndex in controllerIds) {
        if (!controllerIds.hasOwnProperty(controllerIdIndex)) {
          continue;
        }
        let controllerId = String(controllerIds[controllerIdIndex]).trim();
        if (controllerId === '') {
          mergedData = [].concat(mergedData, Object.values(currentChartState[query.dataType][installId][dayCacheKey]));
          continue;
        }
        if (currentChartState[query.dataType][installId][dayCacheKey][controllerId] === 'undefined' ||
            currentChartState[query.dataType][installId][dayCacheKey][controllerId] == null
        ) {
          console.log('[Cache Miss]: ' + query.dataType + ' '  + installId + ' ' + dayCacheKey + ' ' + controllerId);
          return {data: null, result: DataServiceResults.miss};
        }

        if (currentChartState[query.dataType][installId][dayCacheKey][controllerId].length < minimumDatapointsPerDay){
          console.log('[Cache Miss]: ' + query.dataType + ' '  + installId + ' ' + dayCacheKey + ' ' + controllerId + ' - Not Enough Datapoints');
          return {data: null, result: DataServiceResults.miss};
        }

        if (typeof mergedData[controllerId] == 'undefined') {
          mergedData[controllerId] = [];
        }
        let optimiserData =
            Object.values(currentChartState[query.dataType][installId][dayCacheKey][controllerId])
                .filter((datapoint: any) => {
                  //{ time: 12314654698, value: 24.0 }//
                  return datapoint.time >=  query.filter.from.getTime() / 1000 && datapoint.time <=  query.filter.to.getTime() / 1000;
                });
        mergedData[controllerId] = [].concat(mergedData[controllerId], optimiserData);
      }
    }


    let timestampsToDisplay = this.getTimestamps(mergedData, query);
    if (!timestampsToDisplay){
      console.log('[Cache Miss 0x3310]: ' + query.dataType + ' '  + installId + ' Not Enough Datapoints');
      return {data: null, result: DataServiceResults.miss};
    }


    let dataPoints = {};
    //establish time entries to keep
    for (let controllerSerialNumber in mergedData) {
      if (!Object.prototype.hasOwnProperty.call(mergedData, controllerSerialNumber)) {
        continue;
      }

      // if (timestampsToDisplay.length < 1) {
      //   for (let i = 0; i < cacheData.length; i++) {
      //     if (i % query.limit === 0) {
      //       let nthRecord = cacheData.length / query.limit;
      //       for (let i = 0; i < cacheData.length; i++) {
      //         let mod = i % nthRecord;
      //         if (mod < 1 && mod > -1) {
      //           timestampsToDisplay.push(cacheData[i].time);
      //           if (typeof dataPoints[controllerSerialNumber] == 'undefined') {
      //             dataPoints[controllerSerialNumber] = [];
      //           }
      //           dataPoints[controllerSerialNumber].push(cacheData[i])
      //         }
      //       }
      //     }
      //   }
      // }
      // else {
      let failed = false;
      let cacheData = mergedData[controllerSerialNumber];
      let skippedPoints = 0;
      let skippedPointsTolerance = 10// maximumDatapointsInQuery * 0.025; //2.5% tolerance for unmatched points
      let timestampMatchTolerance = maximumDatapointsInQuery * 0.005 //0.5% tolerance // +- 5 seconds is acceptable to the timestamp to display
      for(let timestampToDisplay of timestampsToDisplay){
        let foundTimestamp = false;
        for (let i = 0; i < cacheData.length; i++) {
          let timestampWithinTolerance = false;

          timestampWithinTolerance = (timestampToDisplay > (cacheData[i].time - timestampMatchTolerance) &&
              timestampToDisplay < (cacheData[i].time + timestampMatchTolerance));

          if (timestampWithinTolerance){
            if (typeof dataPoints[controllerSerialNumber] == 'undefined') {
              dataPoints[controllerSerialNumber] = [];
            }
            dataPoints[controllerSerialNumber].push(cacheData[i]);
            foundTimestamp = true;
            break; //found datapoint in cacheStorage, move to next timestamp
          }
        }
        if (!foundTimestamp) {
          skippedPoints++;
          if (skippedPoints > skippedPointsTolerance) {
            failed = true;
            break;
          }
        }
      }
      if (failed){
        continue;
      }
      //attempt to find in cacheStorage
      //



      // for (let i = 0; i < cacheData.length; i++) {
      //
      //   if (timestampsToDisplay.has(cacheData[i].time)) {
      //     if (typeof dataPoints[controllerSerialNumber] == 'undefined') {
      //       dataPoints[controllerSerialNumber] = [];
      //     }
      //
      //     dataPoints[controllerSerialNumber].push(cacheData[i])
      //   }
      //}
      //}
    }

    let dataPointCountValidation = {};

    for (let controllerSerialNumber in dataPoints) {
      if (!Object.prototype.hasOwnProperty.call(dataPoints, controllerSerialNumber)){ continue; }
      if (dataPoints[controllerSerialNumber].length >= timestampsToDisplay.size &&
          timestampsToDisplay.size > query.limit * 0.95) {
        dataPointCountValidation[controllerSerialNumber] = true;
      }
    }
    let enoughDataPointsForAllControllers = false;
    if (Object.prototype.hasOwnProperty.call(query.filter, 'controllerIds') &&
        Array.isArray(query.filter.controllerIds) &&
        query.filter.controllerIds.length > 0
    ) {

      let reducedCountValidation : any = Object.values(query.filter.controllerIds).reduce((total: any , next: any) => {
        total.valid = total.valid && (Object.prototype.hasOwnProperty.call(dataPointCountValidation, next) && dataPointCountValidation[next]);
        return total;
      }, {valid: true});

      enoughDataPointsForAllControllers = reducedCountValidation.valid;
    }else {
      enoughDataPointsForAllControllers = true;
    }
    if (!enoughDataPointsForAllControllers){
      console.log('[Cache Pass 0x3320]: ' + query.dataType + ' '  + installId + ' Not Enough Datapoints');
      return {data: dataPoints, result: DataServiceResults.miss};
    }
    return {data: dataPoints, result: DataServiceResults.hit};
    //return mergedData;

  };


  load (query : DataQuery, callback = (data) => { return data; }, useCache = true)  {

    console.log('[ChartDataRepo Load]: query.filter.controllerIds:' + query.filter.controllerIds +' query.from:' + query.filter.from + ' query.to:' + query.filter.to + 'dataType: ' + query.dataType);



    if (typeof query.filter.from === "string") {
      query.filter.from = parseISO(query.filter.from);
    }

    if (typeof query.filter.to === "string") {
      query.filter.to = parseISO(query.filter.to);
    }

    if (query.mode == null){
      query.mode = DataServiceMode.inline;
    }
    switch(query.mode){
      case DataServiceMode.download:
        useCache = false;
        break;
      case DataServiceMode.stream:
        //not implemented // ignore
        break;
      case DataServiceMode.inline:
      default:
        break;
    }

    if (query.filter.from > query.filter.to){ //from.isAfter(to);
      console.log('[ChartDataRepo Load]: Bad Query: query.from:' + query.filter.from + ' query.to:' + query.filter.to + 'dataType: ' + query.dataType);
      return;
    }
    let cacheResults = { result: null, data: null };
    if (useCache) {
      cacheResults = this.tryFetchDataFromCache(query);
      switch(cacheResults.result){
        case DataServiceResults.hit:
          console.log('[ChartDataRepo Load]: Cache Hit');

          let controllerIds = Object.keys(cacheResults.data);
          console.log('[ChartDataRepo Load] - Received chart data from old query. Stored as cacheStorage but didn\'t update chart');

          console.log('[ChartDataRepo Load] - ' + controllerIds.join(','));

          //cacheStorage data is enough to show and we don't fetch more
          return callback({ isLoaded: true, query: query, data: cacheResults.data, cacheResult: cacheResults.result});
        case DataServiceResults.pass:
          //cached data is enough to show and but not enough points, we go fetch more
          callback({ isLoaded: true, query: query, data: cacheResults.data, cacheResult: cacheResults.result});
          break;
        case DataServiceResults.miss:
        default:
          console.log('[ChartDataRepo Load]: Cache Miss');
          //cata data is not enough to show, and we get fetch more
          break;
      }

    }
    let queryLimit = (typeof query.limit === "number")? query.limit : parseInt(String(query.limit))
    if (isNaN(queryLimit)) {
      queryLimit = -1;
    }

    let params = {
      'data_type': query.dataType,
      'filter[install_id]': query.filter.installId,
      'filter[from]': query.filter.from.toISOString(),
      'filter[to]': query.filter.to.toISOString(),
      'filter[controller_serial_numbers]': query.filter.controllerIds,
      'mode': query.mode,
      'limit': queryLimit
    };

    let queryString = Object.keys(params).map(function(key) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
    }).join('&');
    // // Defining key
    //

    let dataUrl = apiUrl + '/comms-unit/graph?' + queryString;


    let collectedData = {};
    let cacheKeysStored = {};
    if (typeof this.cacheStorage.state[query.dataType] === 'undefined') {
      this.cacheStorage.state[query.dataType] = {};
    }
    if (typeof this.cacheStorage.state[query.dataType][query.filter.installId] === 'undefined') {
      this.cacheStorage.state[query.dataType][query.filter.installId] = {};
    }

    switch(query.mode){
      case DataServiceMode.download:
        fetch(dataUrl, {
          method: 'get',
          credentials: 'include',
        })
            .then((res) => res.blob())
            .then((resBlob) => {
              callback({isLoaded: true, query: query, data: resBlob,  cacheResult: DataServiceResults.pass});
              let filename = 'cqsola-' + query.filter.installId + '-' + query.dataType +'-' + formatDate(query.filter.from) + '-' + formatDate(query.filter.to) + '.csv';
              download(resBlob, filename, 'text/csv' );
            });
        return;
      case DataServiceMode.stream:
        //not implemented // ignore
        break;
      case DataServiceMode.inline:
      default:
        break;
    }



    Papa.parse(dataUrl, {
      worker: true,
      download: true,
      //downloadRequestHeaders: authHeader(),
      withCredentials: true,
      header: true,
      fastMode: true,
      step: function(row) {
        if (row == null || typeof row.data === 'undefined'){
          return;
        }
        if (row.data.timestamp  === ''){
          return;
        }
        let unixTimestamp = parseISO(row.data.timestamp).getTime();//parseDateRFC3339(row.data.timestamp);
        let date = new Date(unixTimestamp * 1000);
        //   const RFC3339datePattern = /^([0-9]+)-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])[Tt]([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9]|60)(\.[0-9]+)?(([Zz])|([\+|\-]([01][0-9]|2[0-3]):[0-5][0-9]))$/;
        //
        //   const [RFC3339, year, month, day, hour, minute, second, usingZ, offset] = RFC3339datePattern.exec(row.data.timestamp);
        //   let offsetOverride = parseInt(offset);
        //   //js time months start at 0 -> 11, so we need to -1 off to get the right timestmap)
        // //  let browserOffset = new Date().getTimezoneOffset() / 60;
        //
        //   let unixTimestamp = Math.round((new Date(year, month-1, day, hour, minute, second)).getTime() / 1000);
        //
        //   // let unixTimestamp = Math.round((new Date(year, month-1, day, hour, minute, second)).getTime() / 1000);
        //
        //
        //
        //   let date = new Date(unixTimestamp * 1000);
        let dayCacheKey = date.getFullYear().toString() + (date.getMonth() + 1).toString().padStart(2, '0')  + date.getDate().toString().padStart(2, '0');

        if (typeof this.cacheStorage.state[query.dataType][query.filter.installId][dayCacheKey] == 'undefined'){
          this.cacheStorage.state[query.dataType][query.filter.installId][dayCacheKey] = {}
        }


        for (let controllerSerialNumber in row.data) {
          if (!row.data.hasOwnProperty(controllerSerialNumber) || controllerSerialNumber === 'timestamp') continue;

          if (typeof this.cacheStorage.state[query.dataType][query.filter.installId][dayCacheKey][controllerSerialNumber] == 'undefined') {
            this.cacheStorage.state[query.dataType][query.filter.installId][dayCacheKey][controllerSerialNumber] = {}
          }
          cacheKeysStored[query.dataType + '_' + query.filter.installId + '_' + dayCacheKey + '_' + controllerSerialNumber] = true

          let datapoint = {
            time: unixTimestamp,
            value: parseFloat(row.data[controllerSerialNumber])
          };
          if (!Object.prototype.hasOwnProperty.call(this.cacheStorage.state[query.dataType][query.filter.installId][dayCacheKey][controllerSerialNumber], unixTimestamp )){
            this.cacheStorage.state[query.dataType][query.filter.installId][dayCacheKey][controllerSerialNumber][unixTimestamp] = datapoint;
          }
          if (typeof collectedData[controllerSerialNumber] === 'undefined'){
            collectedData[controllerSerialNumber] = [];
          }
          collectedData[controllerSerialNumber].push(datapoint);
        }
      },
      complete: function() {
        for (let cacheKey in cacheKeysStored)
        {
          if (!cacheKeysStored.hasOwnProperty(cacheKey)) continue;
          console.log('[Cache Store]: ' + cacheKey);
        }
        return callback({isLoaded: true, query: query, data: collectedData,  cacheResult: DataServiceResults.miss});
      }
    });


  };
  toIsoString (date, commsunitTimeZone)  {

    let dateToProcess = new Date();
    if(!(date instanceof Date)){
      dateToProcess = parseISO(date);
    } else {
      dateToProcess = date;
    }
    let tzo = +commsunitTimeZone*60,//-date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function(num) {
          return (num < 10 ? '0' : '') + num;
        };
    return date.getFullYear() +
        '-' + pad(dateToProcess.getMonth() + 1) +
        '-' + pad(dateToProcess.getDate()) +
        'T' + pad(dateToProcess.getHours()) +
        ':' + pad(dateToProcess.getMinutes()) +
        ':' + pad(dateToProcess.getSeconds()) +
        dif + pad(Math.floor(Math.abs(tzo) / 60)) +
        ':' + pad(Math.abs(tzo) % 60);
  }

  async prepareParamsFromQuery (query : DataQuery, commsunitTimeZone, useCache) {
    //let dataProvider = new DataProvider();

      if (query?.filter?.from === undefined){
        throw new Error("Filter \"from\" is required");
      }
      if (query?.filter?.to === undefined){
        throw new Error("Filter \"to\" is required");
      }

        //return new Promise((resolve, reject) => {
      // if (query?.filter?.from !== undefined && typeof query?.filter?.from === "string" ) {
      //     query.filter.from = parseISO(query?.filter?.from);
      // }
      //
      // if (query?.filter?.to !== undefined && typeof query?.filter?.to === "string" ) {
      //     query.filter.to = parseISO(query?.filter?.to);
      // }

      if (query.mode == null){
        query.mode = DataServiceMode.inline;
      }
      switch(query.mode){
        case DataServiceMode.download:
          useCache = false;
          break;
        case DataServiceMode.stream:
          //not implemented // ignore
          break;
        case DataServiceMode.inline:
        default:
          break;
      }


      if (query.filter.from > query.filter.to){ //from.isAfter(to)
        console.log('[ChartDataRepo Load]: Bad Query: query.from:' + query.filter.from + ' query.to:' + query.filter.to + 'dataType: ' + query.dataType);
        return false;
      }

      let queryLimit = (typeof query.limit === "number")? query.limit : parseInt(String(query.limit));
      if (isNaN(queryLimit)) {
        queryLimit = -1;
      }


      // let getCommsUnitTimeZoneOffset = (installId) => {
      //   const DefaultTimeZoneOffset = 10;
      //   return new Promise( (resolveTimeZoneGet, rejectTimeZoneGet) => {
      //     //fetch from server
      //     if (!Object.prototype.hasOwnProperty.call(window.chartData.timeZoneCache, installId)){
      //
      //       let listQuery = new Query({ filter: {query: installId} });
      //       resolveTimeZoneGet(10)
      //       // dataProvider.CommsUnit().list(listQuery)
      //       //   .then((commsUnitResponse) => {
      //       //     for (let commsUnit in commsUnitResponse.data){
      //       //       window.chartData.timeZoneCache[installId] = commsUnit.time_zone
      //       //     }
      //       //     // setSolarStrings(commsUnitResponse.data);
      //       //     // setRowTotal(commsUnitResponse.meta.total);
      //       //
      //       //     //window.chartData.timeZoneCache[installId] = 10
      //       //     if (!Object.prototype.hasOwnProperty.call(window.chartData.timeZoneCache, installId)) {
      //       //       resolveTimeZoneGet(DefaultTimeZoneOffset)
      //       //     }else {
      //       //       resolveTimeZoneGet(window.chartData.timeZoneCache[installId])
      //       //     }
      //       //
      //       //   }).catch( err => {
      //       //     rejectTimeZoneGet(err)
      //       //   });
      //
      //     }
      //     else {
      //       resolveTimeZoneGet(window.chartData.timeZoneCache[installId])
      //     }
      //
      //   })
      // }

      //the below is tied into the way the react-timeseries-graph works,
      //the graphing system doesn't account for timezones and
      //always produces the timezone that the browser is in.
      //this causes problems to overseas customers, where the browser
      //is in a different timezone to the comms unit.
      //
      //To avoid this, we always submit the correct commsunit timezone with
      //requests, but this means replacing the browser timezone, but keeping the
      //time.
      // getCommsUnitTimeZoneOffset(query.filter.installId).then(commsunitTimeZone => {
      //
      //   let from = new Date(query.filter.from.toDate().getTime())// - (2 * 60 * 60 * 1000) + (10 * 60 * 60 * 1000));
      //   let to = new Date(query.filter.to.toDate().getTime())//  - (2 * 60 * 60 * 1000) + (10 * 60 * 60 * 1000));
      //
      //   resolve({
      //     'data_type': query.dataType,
      //     'filter[install_id]': query.filter.installId,
      //     'filter[from]': toIsoString(from, commsunitTimeZone),
      //     'filter[to]':   toIsoString(to, commsunitTimeZone),
      //     'filter[controller_serial_numbers]': query.filter.controllerIds,
      //     'mode': query.mode,
      //     'limit': queryLimit
      //   });
      // });
      // let commsunitTimeZone = 10;
      let from = (query.filter.from instanceof Date)? new Date(query.filter.from.getTime()) : parseISO(query.filter.from);// - (2 * 60 * 60 * 1000) + (10 * 60 * 60 * 1000));
      let to = (query.filter.to instanceof Date)? new Date(query.filter.to.getTime()) : parseISO(query.filter.to);//  - (2 * 60 * 60 * 1000) + (10 * 60 * 60 * 1000));

      return {
        'data_type': query.dataType,
        'filter[install_id]': query.filter.installId,
        'filter[from]': this.toIsoString(from, commsunitTimeZone),
        'filter[to]':   this.toIsoString(to, commsunitTimeZone),
        'filter[controller_serial_numbers]': query.filter.controllerIds,
        'mode': query.mode,
        'limit': queryLimit
      };
    }


  async loadCSV (query: DataQuery, timezoneOffset, useCache = true) {
    let params = {};
    try
    {
      params = await this.prepareParamsFromQuery(query, timezoneOffset, useCache);
    } catch (err){
      console.error(err);
      throw err;
    }
    return new Promise((resolve, reject) => {
     if (params === false) {
        return;
      }
      let queryString = Object.keys(params).map(function (key) {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
      }).join('&');
      // // Defining key
      //
      let dataUrl =  apiUrl  + '/comms-unit/graph?' + queryString;


      switch (query.mode) {
        case DataServiceMode.download:
          fetch(dataUrl, {
            method: 'get',
            credentials: 'include',
          })
              .then((res) => res.blob())
              .then((resBlob) => {
                let dateFormat = 'YYYY-MM-DD-hhmmss';
                let filename = 'cqsola-' + query.filter.installId + '-' + query.dataType + '-' + formatDate(query.filter.from) + '-' + formatDate(query.filter.to) + '.csv';
                download(resBlob, filename, 'text/csv');
              });
          return;
        case DataServiceMode.stream:
          //not implemented // ignore
          break;
        case DataServiceMode.inline:
        default:
          break;
      }


      let collectedData = [];
      Papa.parse(dataUrl, {
        worker: true,
        download: true,
        //downloadRequestHeaders: authHeader(),
        withCredentials: true,
        header: false,
        fastMode: true,
        step: function (row) {
          if (row == null || typeof row.data === 'undefined') {
            return;
          }
          // if (row.data.timestamp  === ''){
          //   return;
          // }

          //let unixTimestamp = parseDateRFC3339(row.data[0]);
          //let date = new Date(unixTimestamp * 1000);
          collectedData.push(row.data)
          //   const RFC3339datePattern = /^([0-9]+)-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])[Tt]([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9]|60)(\.[0-9]+)?(([Zz])|([\+|\-]([01][0-9]|2[0-3]):[0-5][0-9]))$/;

        },
        complete: function () {
          resolve({
            isLoaded: true,
            query: query,
            data: collectedData,
            cacheResult: DataServiceResults.miss}
          );
        }
      });

    });
  };

  async reset(){
    return this.cacheStorage.reset();

  };

}

export {DeviceDataService};
