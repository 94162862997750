import {createSlice, current } from '@reduxjs/toolkit';
import {objFromArray} from "../utils/obj-from-array";
//import { current } from 'immer';
const initialState = {
    controllers: {
        byQuery: {},
        latestByInstallId: {},
    }
};

export const slice = createSlice({
    name: 'controller',
    initialState,
    reducers: {
        listControllers(state, action) {
            state.controllers.byQuery = {...state.controllers.byQuery, [JSON.stringify(action.payload.query)]: action.payload.result}
            if (Array.isArray(action?.payload?.result?.data)) {
                for (let controller of action.payload.result.data) {
                    let controllers = state.controllers.latestByInstallId?.[controller.install_id] ?? [];
                    controllers.push(controller);
                    state.controllers.latestByInstallId = {...state.controllers.latestByInstallId, [controller.install_id]: controllers}
                }
            }
        },
    }
});

export const {reducer} = slice;