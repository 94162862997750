import {combineReducers} from '@reduxjs/toolkit';
import {reducer as calendarReducer} from '../slices/calendar';
import {reducer as chatReducer} from '../slices/chat';
import {reducer as kanbanReducer} from '../slices/kanban';
import {reducer as mailReducer} from '../slices/mail';
import {reducer as sessionReducer} from '../slices/session';
import {reducer as apiTokenReducer} from '../slices/apiToken';
import {reducer as deviceStatisticsReducer} from '../slices/deviceStatistics';
import {reducer as deviceLocationReducer} from '../slices/deviceLocation';
import {reducer as deviceLocationMetaReducer} from '../slices/deviceLocationMeta';
import {reducer as deviceMetaDataReducer} from '../slices/deviceMetaData';
import {reducer as deviceRegistrationReducer} from '../slices/deviceRegistration';
import {reducer as deviceDataReducer} from '../slices/deviceData';
import {reducer as deviceEventLogReducer} from '../slices/deviceEventLog';
import {reducer as controllerReducer} from '../slices/controller';
import {reducer as userNotificationReducer} from '../slices/userNotification';

export const rootReducer = combineReducers({
    calendar: calendarReducer,
    chat: chatReducer,
    kanban: kanbanReducer,
    mail: mailReducer,
    session: sessionReducer,
    apiToken: apiTokenReducer,
    deviceStatistics: deviceStatisticsReducer,
    deviceMetaData: deviceMetaDataReducer,
    deviceLocation: deviceLocationReducer,
    deviceLocationMeta: deviceLocationMetaReducer,
    deviceRegistration: deviceRegistrationReducer,
    deviceData: deviceDataReducer,
    deviceEventLog: deviceEventLogReducer,
    controller: controllerReducer,
    userNotification: userNotificationReducer
});
