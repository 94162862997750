import {createSlice, current } from '@reduxjs/toolkit';
import {objFromArray} from "../utils/obj-from-array";
//import { current } from 'immer';
const initialState = {
    locationMetas: {
        byLocationIdDate: {},
        byId: {},
    }
};

export const slice = createSlice({
    name: 'deviceLocationMeta',
    initialState,
    reducers: {
        getDeviceLocationMeta(state, action) {
            let locationMeta = action.payload;
            state.locationMetas.byId = {...state.locationMetas.byId, [locationMeta.id]: locationMeta}
            state.locationMetas.byLocationIdDate = {...state.locationMetas.byLocationIdDate, [String(locationMeta.location_id + locationMeta.day)]: locationMeta}
        },
    }
});

export const {reducer} = slice;