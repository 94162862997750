/**
 * Copyright © Tony Schirmer All rights reserved.
 * See LICENSE.txt for license details.
 */
import {dataServiceConfig} from '../../config';
import {formatISO} from "date-fns";
import Query from "../Query";
import phrases from "../phrases";
import NotFoundError from "../errors/NotFoundError";
// import {useTranslation} from "react-i18next";
// import phrases from "../phrases"
//
// const {t} = useTranslation();

// class ModuleHealthRating  {
//
//   id: '';
//   name: '';
//   restrict_to_ip: '';
//   is_active: '';
//   notes: '';
//   updated_at: '';
//   created_at: '';
//
//   constructor(obj) {
//     obj && Object.assign(this, obj);
//   }
// }
//
// class ModuleHealthRatingList {
//   data: Array<ModuleHealthRating>;
//   meta: {
//     total: 0,
//     page:  0,
//     page_size: 0
//   };
// }

const apiUrl = dataServiceConfig.url;
export default class StatisticsService {
  private config: any;

  constructor(config) {
    if (config == null) {
      config = {};
    }
    this.config = config;
  }

  getModuleHealthRatings(): Promise<[]> {
    let service = this;
    let t = (phrase) => {return phrase};
    return new Promise((resolve, reject) => {
            let dataUrl = `${apiUrl}/statistics/module_health/rating`;
      fetch(dataUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({})
      })
          .then(service.config.afterFetch)
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              case 403:
                throw new Error(t(phrases.Errors.Unauthorized));
              case 404:
                reject(new NotFoundError(t('Could not get module health ratings')));
                break;
              default:
                reject( new Error(t('Could not get module health ratings')));
                break;
            }})
          .then(result => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
    });
  }


  //query: Query
  getTotalEnergy(query : Query): Promise<[]> {
    let service = this;

    let now =  new Date(Date.now());
    if (query.filter.from.getTime() == 0){
      query.filter.from = new Date(
          now.getFullYear() , //-20% of the diff rounded.
          now.getMonth(),
          now.getDate(),
          0,
          0,
          0,
          0
      );
    }
    if (query?.filter?.to.getTime() == 0){
      query.filter.to = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          23,
          59,
          59,
          0
      );
    }

    return new Promise((resolve, reject) => {
      let params = {
        // "data_type": query.dataType,
        'from': formatISO(query.filter.from),
        'to': formatISO(query.filter.to),
        'data_type': 'watts',
        'group_by': 'day',
        'totals': 'peak',
        'limit': 10000000
      };


      let queryString = Object.keys(params).map(function(key) {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
      }).join('&');

      let dataUrl = `${apiUrl}/installation/total?${queryString}`;

    //  let dataUrl = `${apiUrl}/statistics/installation/energy`;
      fetch(dataUrl, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        // body: JSON.stringify({})
      })
          .then(service.config.afterFetch)
          .then((response) => {
            return response.json()
          })
          .then(result => {
            resolve(result?.data);
          })
          .catch((error) => {
            reject(error);
          });
    });
  }


}

export {StatisticsService};
