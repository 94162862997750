import {createContext, useCallback, useEffect, useMemo, useReducer, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {authApi} from '../__fake-api__/auth-api';
import {useContext} from 'react';
import {DataServiceContext} from '../contexts/data-service-context';
import DataProvider from "../data";
import {useRouter} from "next/router";

//export const useDataService = () => useContext(DataServiceContext);
var ActionType;
(function (ActionType) {
    ActionType['INITIALIZE'] = 'INITIALIZE';
    ActionType['LOGIN'] = 'LOGIN';
    ActionType['LOGOUT'] = 'LOGOUT';
    ActionType['REGISTER'] = 'REGISTER';
    ActionType['TIMEOUT'] = 'TIMEOUT';
    ActionType['REFRESH'] = 'REFRESH';
})(ActionType || (ActionType = {}));

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
    account: null,
    lastAction: null
};

const handlers = {
    INITIALIZE: (state, action) => {
        const {isAuthenticated, user, account} = action.payload;

        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user,
            account,
            lastAction: ActionType.INITIALIZE
        };
    },
    LOGIN: (state, action) => {
        const {user, account} = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            user,
            account,
            lastAction: ActionType.LOGIN
        };
    },
    LOGOUT: (state) => ({
        ...state,
        isAuthenticated: false,
        user: null,
        account: null,
        lastAction: ActionType.LOGOUT
    }),
    REGISTER: (state, action) => {
        const {user, account} = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            user,
            account,
            lastAction: ActionType.REGISTER
        };
    },
    TIMEOUT: (state, action) => {
        const {user, account} = action.payload;

        return {
            ...state,
            isAuthenticated: false,
            user,
            account,
            lastAction: ActionType.TIMEOUT
        };
    },
    REFRESH: (state, action) => {
        const {user, account} = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            user,
            account,
            lastAction: ActionType.REFRESH
        };
    }
};

const reducer = (state, action) => (handlers[action.type]
    ? handlers[action.type](state, action)
    : state);

export const AuthContext = createContext({
    ...initialState,
    platform: 'CQSolaAuth',
    login: () => Promise.resolve(),
    loginResendMFA: () => Promise.resolve(),
    loginConfirmMFA: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    refreshSession: () => Promise.resolve(),
    registerBegin: () => Promise.resolve(),
    registerTokenIsValid: () => Promise.resolve(),
    registerResendMFA: () => Promise.resolve(),
    registerConfirmMFA: () => Promise.resolve(),
    forgotPassword: () => Promise.resolve(),
    forgotPasswordTokenIsValid: () => Promise.resolve(),
    forgotPasswordConfirmMFA: () => Promise.resolve(),
    forgotPasswordResendMFA: () => Promise.resolve(),
    resetPassword: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
    const {children} = props;
    const [state, dispatch] = useReducer(reducer, initialState);
    const sessionTimeoutHandle = useRef(null);
    const sessionRefreshHandle = useRef(null);
    const router = useRouter();
    const dataProvider = useMemo(() => {return new DataProvider({router: router})}, [router]);
    const [expiryTimeoutSec, setExpiryTimeoutSec] = useState(0);


    const stopSessionTimer = useCallback( () => {
        let sessionTimeoutHandleInstance = sessionTimeoutHandle.current;
        let sessionRefreshHandleInstance = sessionRefreshHandle.current;
        if (sessionRefreshHandleInstance != null) {
            clearTimeout(sessionRefreshHandleInstance)
        }
        if (sessionTimeoutHandleInstance != null) {
            clearTimeout(sessionTimeoutHandleInstance)
        }
    }, []);

    const startSessionTimer = useCallback((user, account, tokenExpiry) => {
        let expiryTimeoutSec = ((tokenExpiry * 1000) - Date.now()) / 1000;

        //let expiryTimeout = 60;
        //start timer to show user they've been logged out
        sessionTimeoutHandle.current = setTimeout(() => {
            stopSessionTimer(); //is timed out, stop all running timers
            dispatch({
                type: ActionType.TIMEOUT,
                payload: {
                    user,
                    account
                }
            });
        }, expiryTimeoutSec * 1000)
        setExpiryTimeoutSec(expiryTimeoutSec)
    },[sessionTimeoutHandle, stopSessionTimer, dispatch])

    const refreshSession = useCallback(async () => {
        let dataProvider = new DataProvider({router: router})
        try {
            const loginRefreshResponse = await dataProvider.User().refreshToken();
            const user = await dataProvider.User().getCurrentUser()
            const account = await dataProvider.Account().getCurrentAccount()
            stopSessionTimer();
            if (loginRefreshResponse?.bearer_token_expiry !== undefined && typeof loginRefreshResponse?.bearer_token_expiry === "number"){
                startSessionTimer(user, account, loginRefreshResponse?.bearer_token_expiry); //re-start timeout handle
            }
            dispatch({
                type: ActionType.REFRESH,
                payload: {
                    user,
                    account
                }
            });
        } catch (err){
            //don't clear timeout, failed to refresh with credentials, allow timeout of session.
        }
    }, [dispatch, router, stopSessionTimer, startSessionTimer]);

    useEffect(() => {
        if (expiryTimeoutSec > 0 ){
            sessionRefreshHandle.current = setTimeout(async () => {
                await refreshSession()
            }, (expiryTimeoutSec - 30) * 1000) //30 seconds before the timeout.
        }
    }, [startSessionTimer, refreshSession, expiryTimeoutSec])


    useEffect(() => {
        const initialize = async () => {
            try {
                const user = await dataProvider.User().getCurrentUser()
                const account = await dataProvider.Account().getCurrentAccount()
                let session = dataProvider.User().getSessionInLocalStorage()
                startSessionTimer(user, account, session.bearer_token_expiry);
                /** @var dataProvider DataProvider */
                dispatch({
                    type: ActionType.INITIALIZE,
                    payload: {
                        isAuthenticated: true,
                        user,
                        account
                    }
                });
            } catch (err) {
                console.error(err);
                dispatch({
                    type: ActionType.INITIALIZE,
                    payload: {
                        isAuthenticated: false,
                        user: null,
                        account: null
                    }
                });
            }
        };

        initialize().then(() => {
            //completed
        });
    }, [startSessionTimer, dispatch, dataProvider ]);



    const login = async (email, password, reCaptchaToken) => {
        let dataProvider = new DataProvider(props)
        //let dataService = useDataService()
        //let dataProvider = dataService.getDataProvider();
        const loginResponse = await dataProvider.User().login(email, password, reCaptchaToken)
        let redirect = null;
        let loginCompleted = false;
        switch (loginResponse.redirect) {
            //the api is calling for a redirect, so the submitted cookies
            //need a 2fa verification, and the api gives the route relative to the backend.
            case '/v1/login/verify':
                redirect = '/authentication/verify-code';
                loginCompleted = false;
                break;
            default:
                loginCompleted = true;
                break;
        }
        loginResponse.redirect = redirect;
        if (loginCompleted) {
            //start modal timer based on session expiry
            const user = await dataProvider.User().getCurrentUser()
            const account = await dataProvider.Account().getCurrentAccount()
            if (loginResponse?.bearer_token_expiry !== undefined && typeof loginResponse?.bearer_token_expiry === "number"){
                startSessionTimer(user, account, loginResponse?.bearer_token_expiry);
            }

            dispatch({
                type: ActionType.LOGIN,
                payload: {
                    user,
                    account
                }
            });
        }
        /** @var dataProvider DataProvider */
        //const user = await dataProvider.User().getCurrentUser()
        //
        //   const {accessToken} = await authApi.login({email, password});
        //   const user = await authApi.me({accessToken});

        // localStorage.setItem('accessToken', accessToken);

        // dispatch({
        //     type: ActionType.LOGINSTART,
        //     payload: {
        //         loginResponse
        //     }
        // });

        return loginResponse;
    };

    const loginResendMFA = async () => {
        let dataProvider = new DataProvider(props)
        return await dataProvider.User().loginResendMFA();
    }

    const loginConfirmMFA = async (mfaToken) => {
        let dataProvider = new DataProvider(props)
        let loginResponse = await dataProvider.User().loginConfirmMFA(mfaToken)
        const user = await dataProvider.User().getCurrentUser()
        const account = await dataProvider.Account().getCurrentAccount()
        //start modal timer based on session expiry
        if (loginResponse?.bearer_token_expiry !== undefined && typeof loginResponse?.bearer_token_expiry === "number"){
            startSessionTimer(user, account, loginResponse?.bearer_token_expiry);
        }
        dispatch({
            type: ActionType.LOGIN,
            payload: {
                user,
                account
            }
        });

    }

    const logout = async () => {
        let dataProvider = new DataProvider(props)
        await dataProvider.User().logout()
        stopSessionTimer()
        dispatch({type: ActionType.LOGOUT});
    };

    const registerBegin = async (
        emailAddress,
        firstName,
        lastName,
        countryCode,
        mobileNumber,
        password,
        passwordConfirm,
        agreedToTerms,
        inviteToken,
        reCaptchaToken,
    ) => {


        let dataProvider = new DataProvider(props)
        //let dataService = useDataService()
        //let dataProvider = dataService.getDataProvider();
        const signUpBeginResponse = await dataProvider.User().signUpBegin(
            emailAddress,
            firstName,
            lastName,
            countryCode,
            mobileNumber,
            password,
            passwordConfirm,
            agreedToTerms,
            inviteToken,
            reCaptchaToken
        )
        let redirect = null;




    };

    const registerTokenIsValid = async (token) => {

        let dataProvider = new DataProvider(props)
        return await dataProvider.User().signUpTokenIsValid(token);
    }
    const registerResendMFA = async (recaptchaToken, signUpToken) => {

        let dataProvider = new DataProvider(props)
        return await dataProvider.User().signUpResendMFA(recaptchaToken, signUpToken);
    }


    const registerConfirmMFA = async (recaptchaToken, signUpToken, mfaToken) => {


        let dataProvider = new DataProvider(props)
        await dataProvider.User().signUpVerifyConfirmMFA(recaptchaToken, signUpToken, mfaToken);
        const user = await dataProvider.User().getCurrentUser()
        const account = await dataProvider.Account().getCurrentAccount()
        dispatch({
            type: ActionType.REGISTER,
            payload: {
                user,
                account
            }
        });
        return true;
    }

    const forgotPassword = async (reCaptchaToken, emailAddress, mobileNumberFragment) => {

        let dataProvider = new DataProvider(props);
        return await dataProvider.User().forgotPassword(reCaptchaToken, emailAddress, mobileNumberFragment);
    }

    const forgotPasswordTokenIsValid = async (token) => {

        let dataProvider = new DataProvider(props);
        return await dataProvider.User().forgotPasswordTokenIsValid(token);
    }

    const forgotPasswordConfirmMFA = async (recaptchaToken, forgotPasswordToken, mfaToken) => {
        let dataProvider = new DataProvider(props)
        let isConfirmed = await dataProvider.User().forgotPasswordConfirmMFA(recaptchaToken, forgotPasswordToken, mfaToken);
        if (isConfirmed) {
            const user = await dataProvider.User().getCurrentUser()
            const account = await dataProvider.Account().getCurrentAccount()
            dispatch({
                type: ActionType.LOGIN,
                payload: {
                    user,
                    account
                }
            });
        }

    }
    const forgotPasswordResendMFA = async (recaptchaToken, forgotPasswordToken) => {
        let dataProvider = new DataProvider(props)
        return await dataProvider.User().forgotPasswordResendMFA(recaptchaToken, forgotPasswordToken);
    }


    const resetPassword = async (reCaptchaToken,
                                 password,
                                 passwordConfirmation,
                                 mfaVerificationToken,
                                 forgotPasswordToken,
                                 currentPassword) => {

        let dataProvider = new DataProvider(props);
        return await dataProvider.User().resetPassword(reCaptchaToken,
            password,
            passwordConfirmation,
            mfaVerificationToken,
            forgotPasswordToken,
            currentPassword);
    }
    return (
        <AuthContext.Provider
            value={{
                ...state,
                platform: 'CQSolaAuth',
                login,
                loginResendMFA,
                loginConfirmMFA,
                logout,
                refreshSession,
                registerBegin,
                registerTokenIsValid,
                registerResendMFA,
                registerConfirmMFA,
                forgotPassword,
                forgotPasswordTokenIsValid,
                forgotPasswordConfirmMFA,
                forgotPasswordResendMFA,
                resetPassword
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export const AuthConsumer = AuthContext.Consumer;
