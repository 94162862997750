/**
 * Copyright © Tony Schirmer All rights reserved.
 * See LICENSE.txt for license details.
 */
import {dataServiceConfig} from '../../config';
import {useTranslation} from "react-i18next";
import phrases from "../phrases"

class UserApiLoginAttempt {

  id: '';
  api_token_id: '';
  api_token_name: '';
  ip_address: '';
  successful: false;
  user_agent: '';
  created_at: '';
  country_code: '';
  asn: '';

  constructor(obj) {
    obj && Object.assign(this, obj);
  }
}

class UserApiLoginAttemptList {
  data: Array<UserApiLoginAttempt>;
  meta: {
    total: 0,
    page: 0,
    page_size: 0
  };
}

const apiUrl = dataServiceConfig.url;
export default class UserApiLoginAttemptService {
  private config: any;

  constructor(config) {
    if (config == null) {
      config = {};
    }
    this.config = config;
  }




  list(query): Promise<UserApiLoginAttemptList> {

    let service = this;
    const t = (phrase) => { return phrase; }
    //const {t} = useTranslation();
    let dataUrl = `${apiUrl}/user/api_login_attempt/list`;
    return new Promise((resolve, reject) => {
      fetch(dataUrl, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(query)
      })
          .then(service.config.afterFetch)
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              case 403:
                throw new Error(t(phrases.Errors.Unauthorized));
              default:
                throw new Error(t('Could not fetch user login attempts'));
            }
          })
          .then(function (response) {

            if (response.hasOwnProperty('error') && response.error != null) {
              reject(new Error(t(response.error)));
            } else {
              //validate response
              if (!Array.isArray(response.data)) {
                reject(new Error(t(phrases.Errors.UnExpectedResponse)));
              }
              let output = new UserApiLoginAttemptList();
              output.data = [];
              for (let i = 0; i < response.data.length; i++) {
                output.data[i] = new UserApiLoginAttempt(response.data[i])
              }
              output.meta = response.meta;
              resolve(output);
            }
          })
          .catch((error) => {
            reject(new Error(t(error)));
          })

    });


  }




}

export {UserApiLoginAttemptService, UserApiLoginAttempt, UserApiLoginAttemptList};
