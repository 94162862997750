import PropTypes from 'prop-types';
import {styled, useTheme} from '@mui/material/styles';


export const Logo = styled((props) => {
    const {variant, ...other} = props;
    const theme = useTheme();
    let mode =  theme?.palette?.mode;
    if (variant == 'splash') {
        mode = "dark";
    }
    let color = mode === 'dark' ? '#f0a411' : '#f0a411';//'#C1C4D6' : '#5048E5';
    let textColor = mode === 'dark' ?  'white' : 'black' ;

    // const textColor = theme?.palette?.mode === 'light' ? '#000000' :  '#ffffff' ;//'#C1C4D6' : '#5048E5';
    // let textColor =  'white';
    // console.log(`initalLoad ${initalLoad} - textcolo ${textColor} - themeMode ${ theme?.palette?.mode}` );
    // if (!initalLoad){
    //
    // }
    // console.log(`initalLoad ${initalLoad} - textcolo ${textColor} - themeMode ${ theme?.palette?.mode}` );
    // initalLoad = false;

    return (


        <svg
            // xmlns:dc="http://purl.org/dc/elements/1.1/"
            // xmlns:cc="http://creativecommons.org/ns#"
            // xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
            //xmlns:svg="http://www.w3.org/2000/svg"
            {...other}
            // className={other?.className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1333.3333 1122.52"
            height="1122.52"
            width="1333.3333"
            id="svg2"
            version="1.1">
            <defs
                id="defs6">
                <clipPath
                    id="clipPath18"
                    clipPathUnits="userSpaceOnUse">
                    <path
                        id="path16"
                        d="M 0,841.89 H 1000 V 0 H 0 Z"/>
                </clipPath>
            </defs>
            <g
                transform="matrix(1.3333333,0,0,-1.3333333,0,1122.52)"
                id="g10">
                <g
                    id="g12">
                    <g
                        clipPath="url(#clipPath18)"
                        id="g14">
                        <g
                            transform="translate(348.2783,359.2719)"
                            id="g20">
                            <path
                                id="path22"
                                fill={textColor}
                                // style="fill:#100f0d;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                d="m 0,0 c 0,33.134 24.609,58.547 58.547,58.547 25.735,0 47.609,-16.085 54.204,-39.568 0.644,-2.734 -0.804,-4.503 -3.539,-4.503 H 90.233 c -2.252,0 -3.539,1.126 -4.504,3.216 C 81.226,27.504 70.771,33.456 58.547,33.456 38.603,33.456 25.735,20.105 25.735,0 c 0,-20.105 12.868,-33.455 32.812,-33.455 12.224,0 22.679,5.951 27.182,15.763 0.965,2.09 2.252,3.216 4.504,3.216 h 18.979 c 2.735,0 4.183,-1.608 3.539,-4.181 -6.273,-23.484 -28.309,-39.89 -54.204,-39.89 C 24.609,-58.547 0,-33.134 0,0"/>
                        </g>
                        <g
                            transform="translate(535.1572,326.7445)"
                            id="g24">
                            <path
                                id="path26"
                                fill={textColor}
                                // style="fill:#100f0d;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                d="m 0,0 -8.671,9.844 c -1.768,2.091 -1.608,3.86 0.483,5.63 L 4.519,26.089 c 2.09,1.769 4.021,1.447 5.629,-0.483 l 9.795,-11.121 c 2.978,5.041 4.681,11.142 4.681,18.037 0,20.106 -14.476,33.456 -32.812,33.456 C -26.524,65.978 -41,52.628 -41,32.522 -41,12.417 -26.524,-0.933 -8.188,-0.933 -5.353,-0.933 -2.61,-0.614 0,0 m 45.855,-14.926 c 1.769,-2.091 1.609,-3.86 -0.482,-5.63 L 32.666,-31.171 c -2.091,-1.769 -4.021,-1.448 -5.63,0.483 l -9.303,10.56 c -7.813,-3.789 -16.613,-5.894 -25.92,-5.894 -32.329,0 -58.546,25.413 -58.546,58.546 0,33.133 26.217,58.547 58.546,58.547 32.33,0 58.547,-25.414 58.547,-58.547 0,-14.448 -4.986,-27.428 -13.29,-37.473 z"/>
                        </g>
                        <g
                            transform="translate(593.7187,334.1801)"
                            id="g28">
                            <path
                                id="path30"
                                fill={textColor}
                                //  style="fill:#100f0d;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                d="m 0,0 c -0.161,2.734 1.447,4.183 4.021,4.183 h 17.693 c 2.412,0 3.699,-1.287 4.182,-3.861 1.287,-5.79 6.112,-8.685 15.601,-8.685 10.455,0 13.833,4.02 13.833,8.685 0,6.595 -8.043,9.49 -17.853,12.867 -15.924,5.469 -36.03,12.064 -36.03,36.672 0,22.519 18.336,33.778 37.477,33.778 18.818,0 36.833,-10.134 38.763,-33.455 0.161,-2.735 -1.448,-4.183 -4.021,-4.183 H 55.973 c -2.412,0 -3.7,1.287 -4.182,3.86 -1.286,5.952 -5.469,8.687 -12.867,8.687 -8.042,0 -11.741,-4.022 -11.741,-8.687 0,-6.755 6.593,-9.971 15.922,-13.349 16.085,-5.63 37.959,-11.42 37.959,-36.19 0,-22.518 -18.014,-33.777 -39.567,-33.777 C 20.427,-33.455 1.93,-23.322 0,0"/>
                        </g>
                        <g
                            transform="translate(743.9424,343.1879)"
                            id="g32">
                            <path
                                id="path34"
                                fill={textColor}
                                //  style="fill:#100f0d;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                d="m 0,0 c 0,11.42 -8.042,18.979 -18.497,18.979 -10.454,0 -18.496,-7.559 -18.496,-18.979 0,-11.42 8.042,-18.979 18.496,-18.979 C -8.042,-18.979 0,-11.42 0,0 m -61.12,0 c 0,23.966 18.979,42.462 42.623,42.462 23.644,0 42.624,-18.496 42.624,-42.462 0,-23.966 -18.98,-42.463 -42.624,-42.463 -23.644,0 -42.623,18.497 -42.623,42.463"/>
                        </g>
                        <g
                            transform="translate(781.7383,306.9984)"
                            id="g36">
                            <path
                                id="path38"
                                fill={textColor}
                                //  style="fill:#100f0d;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                d="m 0,0 v 107.765 c 0,2.573 1.447,4.02 4.021,4.02 h 16.084 c 2.574,0 4.021,-1.447 4.021,-4.02 V 0 c 0,-2.574 -1.447,-4.021 -4.021,-4.021 H 4.021 C 1.447,-4.021 0,-2.574 0,0"/>
                        </g>
                        <g
                            transform="translate(866.8213,325.8168)"
                            id="g40">
                            <path
                                id="path42"
                                fill={textColor}
                                //   style="fill:#100f0d;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                d="m 0,0 c 0,4.986 -4.343,8.042 -12.225,8.042 -7.398,0 -10.936,-3.378 -10.936,-8.042 0,-4.665 3.538,-8.042 10.936,-8.042 C -4.343,-8.042 0,-4.665 0,0 m 23.482,27.825 v -46.643 c 0,-2.575 -1.447,-4.022 -4.02,-4.022 H 4.021 C 1.447,-22.84 0,-21.393 0,-18.818 v 2.297 c -4.312,-5.048 -10.644,-8.571 -20.749,-8.571 -14.154,0 -26.539,8.364 -26.539,25.092 0,16.728 12.385,25.092 26.539,25.092 10.105,0 16.437,-2.853 20.749,-6.134 v 7.259 c 0,7.882 -3.217,11.42 -9.973,11.42 -4.986,0 -8.042,-1.93 -9.329,-4.664 -0.965,-2.252 -1.768,-3.538 -4.181,-3.538 h -15.763 c -2.573,0 -4.182,1.447 -4.021,4.181 2.091,17.371 16.889,26.217 33.294,26.217 17.694,0 33.455,-10.294 33.455,-32.008"/>
                        </g>
                        <g
                            transform="translate(290.7214,433.1735)"
                            id="g44">
                            <path
                                id="path46"
                                fill={color}
                                //  style="fill:#f0a411;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                d="m 0,0 -20.257,-55.534 55.534,-20.257 20.257,55.534 z"/>
                        </g>
                        <g
                            transform="translate(313.8238,496.5075)"
                            id="g48">
                            <path
                                id="path50"
                                fill={color}
                                //  style="fill:#f0a411;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                d="m 0,0 -20.257,-55.534 55.534,-20.257 20.257,55.534 z"/>
                        </g>
                        <g
                            transform="translate(336.9261,559.8415)"
                            id="g52">
                            <path
                                id="path54"
                                fill={color}
                                //  style="fill:#f0a411;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                d="m 0,0 -20.257,-55.534 55.534,-20.257 20.257,55.534 z"/>
                        </g>
                        <g
                            transform="translate(388.006,506.0261)"
                            id="g56">
                            <path
                                id="path58"
                                fill={color}
                                //   style="fill:#f0a411;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                d="m 0,0 -20.257,-55.534 55.534,-20.257 20.257,55.534 z"/>
                        </g>
                        <g
                            transform="translate(217.7294,428.3441)"
                            id="g60">
                            <path
                                id="path62"
                                fill={color}
                                //    style="fill:#f0a411;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                d="m 0,0 -20.257,-55.534 55.534,-20.257 20.257,55.534 z"/>
                        </g>
                        <g
                            transform="translate(240.8318,491.6781)"
                            id="g64">
                            <path
                                id="path66"
                                fill={color}
                                //  style="fill:#f0a411;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                d="m 0,0 -20.257,-55.534 55.534,-20.257 20.257,55.534 z"/>
                        </g>
                        <g
                            transform="translate(164.7556,479.1287)"
                            id="g68">
                            <path
                                id="path70"
                                fill={color}
                                //   style="fill:#f0a411;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                d="m 0,0 -20.257,-55.534 55.534,-20.257 20.257,55.534 z"/>
                        </g>
                        <g
                            transform="translate(113.1738,535.1386)"
                            id="g72">
                            <path
                                id="path74"
                                fill={color}
                                //    style="fill:#f0a411;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                d="m 0,0 -20.257,-55.534 55.534,-20.257 20.257,55.534 z"/>
                        </g>
                        <g
                            transform="translate(187.858,542.4627)"
                            id="g76">
                            <path
                                id="path78"
                                fill={color}
                                //    style="fill:#f0a411;fill-opacity:1;fill-rule:nonzero;stroke:none"
                                d="m 0,0 -20.257,-55.534 55.534,-20.257 20.257,55.534 z"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    );
})``;

Logo.defaultProps = {
    variant: 'primary'
};

Logo.propTypes = {
    variant: PropTypes.oneOf(['light', 'primary', 'splash'])
};

// import PropTypes from 'prop-types';
// import {styled} from '@mui/material/styles';
//
// export const Logo = styled((props) => {
//     const {variant, ...other} = props;
//
//     const color = variant === 'light' ? '#f0a411' : '#f0a411';//'#C1C4D6' : '#5048E5';
//
//     return (
//         <svg xmlns="http://www.w3.org/2000/svg" height="100%" width="100%" id="svg2" version="1.1"
//              className="css-1jmoofa" viewBox="0 0 600 800">
//             <g transform="matrix(1,0,0,-1,-90,565)" id="g10">
//                 <g id="g12">
//                     <g id="g14">
//                         <g transform="translate(290.7214,433.1735)" id="g44">
//                             <path id="path46" fill="#f0a411"
//                                   d="m 0,0 -20.257,-55.534 55.534,-20.257 20.257,55.534 z"></path>
//                         </g>
//                         <g transform="translate(313.8238,496.5075)" id="g48">
//                             <path id="path50" fill="#f0a411"
//                                   d="m 0,0 -20.257,-55.534 55.534,-20.257 20.257,55.534 z"></path>
//                         </g>
//                         <g transform="translate(336.9261,559.8415)" id="g52">
//                             <path id="path54" fill="#f0a411"
//                                   d="m 0,0 -20.257,-55.534 55.534,-20.257 20.257,55.534 z"></path>
//                         </g>
//                         <g transform="translate(388.006,506.0261)" id="g56">
//                             <path id="path58" fill="#f0a411"
//                                   d="m 0,0 -20.257,-55.534 55.534,-20.257 20.257,55.534 z"></path>
//                         </g>
//                         <g transform="translate(217.7294,428.3441)" id="g60">
//                             <path id="path62" fill="#f0a411"
//                                   d="m 0,0 -20.257,-55.534 55.534,-20.257 20.257,55.534 z"></path>
//                         </g>
//                         <g transform="translate(240.8318,491.6781)" id="g64">
//                             <path id="path66" fill="#f0a411"
//                                   d="m 0,0 -20.257,-55.534 55.534,-20.257 20.257,55.534 z"></path>
//                         </g>
//                         <g transform="translate(164.7556,479.1287)" id="g68">
//                             <path id="path70" fill="#f0a411"
//                                   d="m 0,0 -20.257,-55.534 55.534,-20.257 20.257,55.534 z"></path>
//                         </g>
//                         <g transform="translate(113.1738,535.1386)" id="g72">
//                             <path id="path74" fill="#f0a411"
//                                   d="m 0,0 -20.257,-55.534 55.534,-20.257 20.257,55.534 z"></path>
//                         </g>
//                         <g transform="translate(187.858,542.4627)" id="g76">
//                             <path id="path78" fill="#f0a411"
//                                   d="m 0,0 -20.257,-55.534 55.534,-20.257 20.257,55.534 z"></path>
//                         </g>
//                     </g>
//                 </g>
//             </g>
//         </svg>
//
//
//     );
// })``;
//
// Logo.defaultProps = {
//     variant: 'primary'
// };
//
// Logo.propTypes = {
//     variant: PropTypes.oneOf(['light', 'primary'])
// };
