/**
 * Copyright © Tony Schirmer All rights reserved.
 * See LICENSE.txt for license details.
 */
import AccountService from './AccountService';
import CommsUnitService from './CommsUnitService';
import ControllerService from "./ControllerService";
import DeviceEventLogService from './DeviceEventLogService';
import DeviceRegistrationService from './DeviceRegistrationService';
import InsightService from './InsightService';
import DeviceMetaDataService from './DeviceMetaDataService';
import DeviceLocationService from './DeviceLocationService';
import PaymentService from './PaymentsService';
import SessionService from './SessionService';
import StatisticsService from './StatisticsService';
import SubscriptionService from './SubscriptionService';
import UserApiTokenService from './UserApiTokenService';
import UserApiLoginAttemptService from "./UserApiLoginAttemptService";
import UserLoginAttemptService from './UserLoginAttemptService';
import UserNotificationService from "./UserNotificationService";
import UserService from './UserService';
import DeviceDataService from "./DeviceDataService";
import DeviceLocationMetaService from "./DeviceLocationMetaService";


export default class DataProvider {
  static _instance: DataProvider;
  private readonly accountService: AccountService;
  private readonly commsUnitService: CommsUnitService;
  private readonly controllerService: ControllerService;
  private readonly deviceEventLogService: DeviceEventLogService;
  private readonly deviceRegistrationService: DeviceRegistrationService;
  private readonly insightService: InsightService;
  private readonly deviceDataService: DeviceDataService;
  private readonly deviceMetaDataService: DeviceMetaDataService;
  private readonly deviceLocationService: DeviceLocationService;
  private readonly deviceLocationMetaService: DeviceLocationMetaService;
  private readonly sessionService: SessionService;
  private readonly statisticsService: StatisticsService;
  private readonly userService: UserService;
  private readonly userLoginAttemptService: UserLoginAttemptService;
  private readonly userApiTokenService: UserApiTokenService;
  private readonly userApiLoginAttemptService: UserApiLoginAttemptService;
  private readonly paymentService: PaymentService;
  private readonly subscriptionService: SubscriptionService;
  private readonly userNotificationService: UserNotificationService;
  private readonly config: {
    errorHandlers: any;
    afterFetch: Function;
  };

  constructor(props) {
    let errorHandlers = null
    if (props?.errorHandlers !== undefined){
      errorHandlers = props.errorHandlers;
    }
    this.config = {
        errorHandlers: (errorHandlers != null) ? errorHandlers : this.generateErrorHandlers(props?.router),
        afterFetch: this.afterFetchIntercept.bind(this)
    };
    this.accountService = new AccountService(this.config);
    this.commsUnitService = new CommsUnitService(this.config);
    this.controllerService = new ControllerService(this.config);
    this.deviceDataService = new DeviceDataService(this.config);
    this.deviceEventLogService = new DeviceEventLogService(this.config);
    this.deviceLocationService = new DeviceLocationService(this.config);
    this.deviceLocationMetaService = new DeviceLocationMetaService(this.config);
    this.deviceMetaDataService = new DeviceMetaDataService(this.config);
    this.deviceRegistrationService = new DeviceRegistrationService(this.config);
    this.insightService = new InsightService(this.config);
    this.paymentService = new PaymentService(this.config);
    this.sessionService = new SessionService(this.config);
    this.statisticsService = new StatisticsService(this.config);
    this.userService = new UserService(this.config);
    this.userNotificationService = new UserNotificationService(this.config);
    this.userLoginAttemptService = new UserLoginAttemptService(this.config);
    this.userApiTokenService = new UserApiTokenService(this.config);
    this.userApiLoginAttemptService = new UserApiLoginAttemptService(this.config);
    this.subscriptionService = new SubscriptionService(this.config);

  }


  static getInstance = function (config): DataProvider {
    if (!DataProvider._instance) {
      DataProvider._instance = new DataProvider(config);
    }
    return DataProvider._instance;
  }


  // static Init = function(config): DataProvider {
  //   if (window.__cqdata_data_service_instance == null){
  //     window.__cqdata_data_service_instance = new DataProvider(config);
  //   }
  // };

  // static GetInstance = function(): DataProvider {
  //   //todo: could do this with react context
  //   //but at the moment there are no react dependencies here
  //   if (window.__cqdata_data_service_instance == null){
  //     throw new Error('Data needs Initialisation. Please run Data.Init() with your config first');
  //   }
  //   return window.__cqdata_data_service_instance;
  // };

  afterFetchIntercept(response) {
    if (typeof response.status !== 'number') {
      return response;
    }
    if (Object.prototype.hasOwnProperty.call(this.config.errorHandlers, response.status) && typeof this.config.errorHandlers[response.status] === 'function') {
      this.config.errorHandlers[response.status]();
    }
    return response;
  }

  generateErrorHandlers(router) {
    return {
      403: (response) => {
        //redirect to login handler.
        //403's don't immediately mean to redirect to login. only 403's with unauthorized tokens.
        if (response == null) {
          return;
        }
        if (!Object.prototype.hasOwnProperty.call(response, 'message')) {
          return;
        }
        switch (response.message) {
          case 'Invalid authorization token':
            if (router) {
              router.push('/login');
            } else {
              throw new Error(response.message);
            }
            return;
          default:
            break;
        }
        return;
      }
    };
  }

  Account(): AccountService {
    return this.accountService;
  }

  CommsUnit(): CommsUnitService {
    return this.commsUnitService;
  }

  Controller(): ControllerService {
    return this.controllerService;
  }

  DeviceEventLog(): DeviceEventLogService {
    return this.deviceEventLogService;
  }

  DeviceMetaData(): DeviceMetaDataService {
    return this.deviceMetaDataService;
  }

  DeviceRegistration(): DeviceRegistrationService {
    return this.deviceRegistrationService;
  }

  Insight(): InsightService {
    return this.insightService;
  }

  DeviceLocation(): DeviceLocationService {
    return this.deviceLocationService;
  }

  DeviceLocationMeta(): DeviceLocationMetaService {
    return this.deviceLocationMetaService;
  }

  DeviceData(): DeviceDataService {
    return this.deviceDataService;
  }

  Payments(): PaymentService {
    return this.paymentService;
  }

  Session(): SessionService {
    return this.sessionService;
  }

  Subscription(): SubscriptionService {
    return this.subscriptionService;
  }

  Statistics(): StatisticsService {
    return this.statisticsService;
  }


  User(): UserService {
    return this.userService;
  }

  UserNotification(): UserNotificationService {
    return this.userNotificationService;
  }
  UserLoginAttempt(): UserLoginAttemptService {
    return this.userLoginAttemptService;
  }

  UserApiLoginAttempt(): UserApiLoginAttemptService {
    return this.userApiLoginAttemptService;
  }

  UserApiToken(): UserApiTokenService {
    return this.userApiTokenService;
  }

}

export {default as DataProvider} from '.';
