import {createContext, useEffect, useReducer} from 'react';
import PropTypes from 'prop-types';
import "typescript";
// import {authApi} from '../__fake-api__/auth-api';
//
// import { createBrowserHistory } from 'history';

// import AccountService from '../data/AccountService';
// import CommsUnitService from '../data/CommsUnitService';
// import DeviceEventLogService from '../data/DeviceEventLogService';
// import DeviceRegistrationService from '../data/DeviceRegistrationService';
// import InsightService from '../data/InsightService';
// import SessionService from '../data/SessionService';
// import StatisticsService from '../data/StatisticsService';
// import UserApiTokenService from '../data/UserApiTokenService';
// import UserService from '../data/UserService';
import DataProvider from '../data'

var ActionType;
(function (ActionType) {
    // ActionType['INITIALIZE'] = 'INITIALIZE';
    // ActionType['LOGIN'] = 'LOGIN';
    // ActionType['LOGOUT'] = 'LOGOUT';
    // ActionType['REGISTER'] = 'REGISTER';
})(ActionType || (ActionType = {}));

const initialState = {
    // isAuthenticated: false,
    // isInitialized: false,
    // user: null
};

const handlers = {
    // INITIALIZE: (state, action) => {
    //     const {isAuthenticated, user} = action.payload;
    //
    //     return {
    //         ...state,
    //         isAuthenticated,
    //         isInitialized: true,
    //         user
    //     };
    // },
    // LOGIN: (state, action) => {
    //     const {user} = action.payload;
    //
    //     return {
    //         ...state,
    //         isAuthenticated: true,
    //         user
    //     };
    // },
    // LOGOUT: (state) => ({
    //     ...state,
    //     isAuthenticated: false,
    //     user: null
    // }),
    // REGISTER: (state, action) => {
    //     const {user} = action.payload;
    //
    //     return {
    //         ...state,
    //         isAuthenticated: true,
    //         user
    //     };
    // }
};

const reducer = (state, action) => (handlers[action.type]
    ? handlers[action.type](state, action)
    : state);

export const DataServiceContext = createContext({
    ...initialState,
    getDataProvider: function () {
    }
});

//const dataProvider = React.useContext(DataService);

export const DataServiceProvider = (props) => {
    const {children} = props;
    const [state, dispatch] = useReducer(reducer, initialState);
    //todo: see if we need to re-write this for less class-based thinking
    const getDataProvider = () => {
        return new DataProvider(props)
    }

    //
    // const login = async (email, password) => {
    //     const {accessToken} = await authApi.login({email, password});
    //     const user = await authApi.me({accessToken});
    //
    //     localStorage.setItem('accessToken', accessToken);
    //
    //     dispatch({
    //         type: ActionType.LOGIN,
    //         payload: {
    //             user
    //         }
    //     });
    // };
    //
    // const logout = async () => {
    //     localStorage.removeItem('accessToken');
    //     dispatch({type: ActionType.LOGOUT});
    // };
    //
    // const register = async (email, name, password) => {
    //     const {accessToken} = await authApi.register({email, name, password});
    //     const user = await authApi.me({accessToken});
    //
    //     localStorage.setItem('accessToken', accessToken);
    //
    //     dispatch({
    //         type: ActionType.REGISTER,
    //         payload: {
    //             user
    //         }
    //     });
    // };

    return (
        <DataServiceContext.Provider
            value={{
                ...state,
                getDataProvider
            }}
        >
            {children}
        </DataServiceContext.Provider>
    );
};

DataServiceProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export const DataServiceConsumer = DataServiceContext.Consumer;
