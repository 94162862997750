/**
 * Copyright © Tony Schirmer All rights reserved.
 * See LICENSE.txt for license details.
 */
import {dataServiceConfig} from '../../config';
//import {useTranslation} from "react-i18next";
import phrases from "../phrases"
import NotFoundError from "../errors/NotFoundError";
class DeviceLocation {


  id: null;
  name: '';
  parent_device_location_id: '';
  updated_at: '';
  created_at: '';

  constructor(obj) {
    obj && Object.assign(this, obj);
  }
  ToNativeJsObject() : any {
    return Object.assign({}, this);
  }
}

class DeviceLocationList {
  data: Array<DeviceLocation>;
  meta: {
    total: 0,
    page: 0,
    page_size: 0
  };

  ToNativeJsObject() : any {
    let output = {
      data: [],
      meta: {
        total: 0,
        page: 0,
        page_size: 0
      }
    };
    for (let i=0; i< this.data.length; i++) {
      output.data[i] = Object.assign({}, this.data[i]);
    }
    output.meta = Object.assign({}, this.meta);
    return output;
  }
}

const apiUrl = dataServiceConfig.url;
export default class DeviceLocationService {
  private config: any;

  constructor(config) {
    if (config == null) {
      config = {};
    }
    this.config = config;
  }


  get(deviceLocationId): Promise<DeviceLocation> {
    let service = this;
    const t = (phrase) => { return phrase}; // useTranslation();
    return new Promise((resolve, reject) => {
      if (! (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(deviceLocationId))){
        reject(new Error("id is not a uuid"));
      }

      let dataUrl = `${apiUrl}/device_location/get`;
      fetch(dataUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          id: deviceLocationId,
        })
      })
          .then(service.config.afterFetch)
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              case 403:
                throw new Error(t(phrases.Errors.Unauthorized));
              case 404:
                reject(new NotFoundError(t('Could not get device location')));
                break;
              default:
                reject( new Error(t('Could not get device location')));
                break;
            }})
          .then(result => {
            resolve(new DeviceLocation(result?.data));
          })
          .catch((error) => {
            reject(error);
          });
    });
  }

  save(deviceLocation) {
    // remove user from local storage to log user out
    let service = this;

    const t = (phrase) => { return phrase};
    return new Promise((resolve, reject) => {
      // if (deviceLocation.id != null  && ! (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(deviceLocation.id))){
      //   reject(new Error("id is not a uuid"));
      // }
      if (deviceLocation?.id === ""){
        deviceLocation.id = null;
      }

      let dataUrl = `${apiUrl}/device_location/save`;
      fetch(dataUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(deviceLocation)
        //   id: deviceLocation.id,
        //   name: deviceLocation.name,
        //   parent_device_deviceLocation_id: deviceLocation.parent_device_location_id,
        // })
      })
          .then(service.config.afterFetch)
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              case 403:
                throw new Error(t(phrases.Errors.Unauthorized));
              default:
                throw new Error(t('Could not save device location'));
            }})
          .then(result => {
            if (Object.prototype.hasOwnProperty.call(result, 'error') &&
                result.error != null
            ) {
              console.error(result.error);
              reject(result.error);
              return;
            }
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
    });
  };

  list(query): Promise<DeviceLocationList> {

    let service = this;
    const t = (phrase) => { return phrase};
    let dataUrl = `${apiUrl}/device_location/list`;
    return new Promise((resolve, reject) => {
      fetch(dataUrl, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(query)
      })
          .then(service.config.afterFetch)
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              case 403:
                throw new Error(t(phrases.Errors.Unauthorized));
              default:
                throw new Error(t('Could not fetch device locations'));
            }
          })
          .then(function (response) {
            // let expectedResponseFields = [
            //   'error',
            //   'meta',
            //   'data'
            // ];
            //
            // let isValidResponse = !expectedResponseFields.map((field) => {
            //   return Object.prototype.hasOwnProperty.call(response, field)
            // }).includes(false);
            //
            // if (!isValidResponse){
            //   reject(phrases.Errors.UnExpectedResponse);
            // }
            if (response.hasOwnProperty('error') && response.error != null) {
              reject(new Error(t(response.error)));
            } else {
              //validate response
              if (!Array.isArray(response.data)) {
                reject(new Error(t(phrases.Errors.UnExpectedResponse)));
              }
              let output = new DeviceLocationList();
              output.data = [];
              for (let i = 0; i < response.data.length; i++) {
                output.data[i] = new DeviceLocation(response.data[i])
              }
              output.meta = response.meta;
              resolve(output);
            }
          })
          .catch((error) => {
            reject(new Error(t(error)));
          })

    });


  }

  delete(deviceLocationId) {

    let service = this;
    //const {t} = useTranslation();
    const t = (phrase) => { return phrase};
    return new Promise((resolve, reject) => {

      let dataUrl = `${apiUrl}/device_location/delete`;
      fetch(dataUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          id: deviceLocationId,
        })
      })
          .then(service.config.afterFetch)
          .then((response) => {
            return response.json()
          })
          .then(result => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
    });
  }


  allocateToLocation(deviceLocationId, deviceRegistrationIds) {
    let service = this;
    //const {t} = useTranslation();
    const t = (phrase) => { return phrase};
    return new Promise((resolve, reject) => {

      let dataUrl = `${apiUrl}/device_location/allocate`;
      fetch(dataUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          location_id: deviceLocationId,
          registered_device_ids: deviceRegistrationIds,
        })
      })
          .then(service.config.afterFetch)
          .then((response) => {
            return response.json()
          })
          .then(result => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
    });
  }
  deallocateFromLocation(deviceRegistrationIds) {
    let service = this;
    //const {t} = useTranslation();
    const t = (phrase) => { return phrase};
    return new Promise((resolve, reject) => {

      let dataUrl = `${apiUrl}/device_location/deallocate`;
      fetch(dataUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          registered_device_ids: deviceRegistrationIds,
        })
      })
          .then(service.config.afterFetch)
          .then((response) => {
            return response.json()
          })
          .then(result => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
    });
  }

}

export {DeviceLocationService, DeviceLocation, DeviceLocationList};
