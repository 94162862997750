import {createSlice, current } from '@reduxjs/toolkit';
import {objFromArray} from "../utils/obj-from-array";
//import { current } from 'immer';
const initialState = {
    locations: {
        byQuery: {},
        byId: {},
    }
};

export const slice = createSlice({
    name: 'deviceLocation',
    initialState,
    reducers: {
        listDeviceLocations(state, action) {
            state.locations.byQuery = {...state.locations.byQuery, [action.payload.query]: action.payload.result}
            for (let location of action.payload.result.data) {
                state.locations.byId[location.id] = location;
            }
        },
        getDeviceLocation(state, action) {
            let location = action.payload;
            //console.log(current(action.payload));
            state.locations.byId = {...state.locations.byId, [location.id]: location}
            //state.locations.byId[location.id] = location;
            //todo: create pidgeon hole cache for queries
            state.locations.byQuery = [];
        },
        saveDeviceLocation(state, action) {
            let location = action.payload;
            state.locations.byId[location.id] = location;
            //todo: create pidgeon hole cache for queries
            state.locations.byQuery = [];
        },
        deleteDeviceLocation(state, action) {
            let location = action.payload;
            delete state.locations.byId[location.id];
            //todo: create pidgeon hole cache for queries
            state.locations.byQuery = [];
        },
    }
});

export const {reducer} = slice;