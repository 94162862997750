import {createSlice, current } from '@reduxjs/toolkit';
import {objFromArray} from "../utils/obj-from-array";
// import DeviceDataCacheEngine from "../data/DeviceDataService/DeviceDataCacheEngine";
import DeviceDataCacheStorage from "../data/DeviceDataService/DeviceDataCacheStorage";
//import { current } from 'immer';
const initialState = {
    data: {
        byQuery: {},
        byTimestamp: {} //new DeviceDataCacheStorage(),
        //byId: {},
    },
    permission: {},
    total: {}
};

export const slice = createSlice({
    name: 'deviceData',
    initialState,
    reducers: {
        listDeviceData(state, action) {
            state.data.byQuery = {...state.data.byQuery, [JSON.stringify(action.payload.arguments.query)]: action.payload.result}
            // let newCacheData =  structuredClone(toRaw(state.data.byTimestamp));
            // let cacheEngine = new DeviceDataCacheEngine({data: newCacheData ?? {} });
            // let query = JSON.parse(action.payload.arguments.query);
            // for (let row of action.payload.result.data) {
            //     cacheEngine.addToCacheByRow(row, query);
            // }
            // state.data.byTimestamp = cacheEngine.data;
            //merge(newCacheData, state?.data?.byTimestamp);
            // for (let location of action.payload.result.data) {
            //     state.data.byId[location.id] = location;
            // }
        },

        getPermission(state, action) {
            state.permission = {...state.permission, [action.payload.arguments.installId]: action.payload.result}
        },

        getTotal(state, action) {
            state.total = {...state.total, [JSON.stringify(action.payload.arguments.query)]: action.payload.result}
        },

        // getDeviceLocation(state, action) {
        //     let location = action.payload;
        //     //console.log(current(action.payload));
        //     state.locations.byId = {...state.locations.byId, [location.id]: location}
        //     //state.locations.byId[location.id] = location;
        //     //todo: create pidgeon hole cache for queries
        //     state.locations.byQuery = [];
        // },
        // saveDeviceLocation(state, action) {
        //     let location = action.payload;
        //     state.locations.byId[location.id] = location;
        //     //todo: create pidgeon hole cache for queries
        //     state.locations.byQuery = [];
        // },
        // deleteDeviceLocation(state, action) {
        //     let location = action.payload;
        //     delete state.locations.byId[location.id];
        //     //todo: create pidgeon hole cache for queries
        //     state.locations.byQuery = [];
        // },
    }
});

export const {reducer} = slice;