import {createSlice, current } from '@reduxjs/toolkit';
import {objFromArray} from "../utils/obj-from-array";
//import { current } from 'immer';
const initialState = {
    registrations: {
        byQuery: {},
        byId: {},
    }
};

export const slice = createSlice({
    name: 'deviceRegistration',
    initialState,
    reducers: {
        listDeviceRegistrations(state, action) {
            state.registrations.byQuery = {...state.registrations.byQuery, [action.payload.query]: action.payload.result}
            for (let registration of action.payload.result.data) {
                state.registrations.byId[registration.id] = registration;
            }
        },
        getDeviceRegistration(state, action) {
            let registration = action.payload;
            //console.log(current(action.payload));
            //state.registrations.byId = {...state.registrations.byId, [registration.id]: registration}
            state.registrations.byId[registration.id] = registration;
            //todo: create pidgeon hole cache for queries
            //state.registrations.byQuery = [];
        },
        claimDeviceRegistration(state, action) {
            let registration = action.payload;
            state.registrations.byId[registration.id] = registration;
            //todo: create pidgeon hole cache for queries
            state.registrations.byQuery = [];
        },
        releaseDeviceRegistration(state, action) {
            let registration = action.payload;
            delete state.registrations.byId[registration.id];
            //todo: create pidgeon hole cache for queries
            state.registrations.byQuery = [];
        },
        allocateToDeviceLocation(state, action) {
            let registration = action.payload;
            state.registrations.byId[registration.id] = {...state.registrations.byId[registration.id], 'location_id': action?.location_id}
            //todo: create pidgeon hole cache for queries
            //for state.registrations.byQuery
            //        update location id
            state.registrations.byQuery = [];
        },
        deallocateFromDeviceLocation(state, action) {
            let registration = action.payload;
            state.registrations.byId[registration.id] = {...state.registrations.byId[registration.id], 'location_id': undefined}
            //todo: create pidgeon hole cache for queries
            //for state.registrations.byQuery
            //        update location id
            state.registrations.byQuery = [];
        }
    }
});

export const {reducer} = slice;