import {createSlice} from '@reduxjs/toolkit';
import {deleteCurrentAccountCancel, deleteCurrentAccountStart} from "../thunks/session";

const initialState = {
    user: {},
    account: {},
    accountDeleteStatus: {},
    sessionContext: {}
    // session: {
    //
    // }
};

export const slice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        getCurrentUser(state, action) {
            state.user = action.payload;
        },
        getCurrentAccount(state, action) {
            state.account = action.payload;
        },
        getCurrentSessionContext(state, action) {
            state.sessionContext = action.payload;
        },
        deleteCurrentAccountStart(state, action){
            state.accountDeleteStatus = action.payload;
        },
        deleteCurrentAccountStatus(state, action) {
            state.accountDeleteStatus = action.payload;
        },
        deleteCurrentAccountCancel(state, action){
            state.accountDeleteStatus = {};
        }

    }
});

export const {reducer} = slice;