import {createSlice, current } from '@reduxjs/toolkit';
import {objFromArray} from "../utils/obj-from-array";
//import { current } from 'immer';
const initialState = {
    metaData: {
        byQuery: {},
        byInstallId: {},
    }
};

export const slice = createSlice({
    name: 'deviceMetaData',
    initialState,
    reducers: {
        // listDeviceLastSeens(state, action) {
        //     state.lastSeens.byQuery = {...state.lastSeens.byQuery, [action.payload.query]: action.payload.result}
        //     for (let lastSeen of action.payload.result.data) {
        //         state.lastSeens.byId = {...state.lastSeens.byId, [lastSeen?.id]: lastSeen};
        //         state.lastSeens.byInstallId = {...state.lastSeens.byInstallId, [lastSeen?.install_id]: lastSeen};
        //         state.lastSeens.bySerialNumber = {...state.lastSeens.bySerialNumber, [lastSeen?.serial_number]: lastSeen};
        //         // state.lastSeens.byId[lastSeen?.id] = lastSeen;
        //         // state.lastSeens.byInstallId[lastSeen?.install_id] = lastSeen;
        //         // state.lastSeens.bySerialNumber[lastSeen?.serial_number] = lastSeen;
        //     }
        // },
        getDeviceMetaData(state, action) {
            let lastSeen = action.payload;

            state.metaData.byInstallId = {...state.metaData.byInstallId, [lastSeen?.install_id]: lastSeen};
            //todo: create pidgeon hole cache for queries
            //state.registrations.byQuery = [];
        },
    }
});

export const {reducer} = slice;