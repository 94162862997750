/**
 * Copyright © Tony Schirmer All rights reserved.
 * See LICENSE.txt for license details.
 */
import {dataServiceConfig} from '../../config';
import {useTranslation} from "react-i18next";
import phrases from "../phrases"
import parseISO from 'date-fns/parseISO';

const SubscriptionTypeGroupNames = {
  Data: "data",
  Support: "support"
};

const SubscriptionTypeNames = {
  Evaluation: "evaluation",
  Basic: "basic",
  Enterprise: "enterprise"
};

class Subscription {


  id: '';
  name: '';
  restrict_to_ip: '';
  is_active: '';
  notes: '';
  updated_at: '';
  created_at: '';

  constructor(obj) {
    obj && Object.assign(this, obj);
  }
}

class SubscriptionList {
  data: Array<Subscription>;
  meta: {
    total: 0,
    page: 0,
    page_size: 0
  };
}

const apiUrl = dataServiceConfig.url;
export default class SubscriptionService {
  private config: any;

  constructor(config) {
    if (config == null) {
      config = {};
    }
    this.config = config;
  }


  get(subscriptionId): Promise<Subscription> {
    let service = this;
    const t = (phrase) => { return phrase}; // useTranslation();
    return new Promise((resolve, reject) => {
      if (! (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(subscriptionId))){
        reject(new Error("id is not a uuid"));
      }
      const requestOptions: RequestInit = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        credentials: 'include',
        body: JSON.stringify({
          id: subscriptionId,
        })
      };
      let dataUrl = `${apiUrl}/subscription/get`;
      fetch(dataUrl, requestOptions)
          .then(service.config.afterFetch)
          .then((response) => {
            return response.json()
          })
          .then(result => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
    });
  }

  getCurrentSubscription(): Promise<Subscription> {
    let service = this;
    const t = (phrase) => { return phrase}; // useTranslation();
    return new Promise((resolve, reject) => {
      const requestOptions: RequestInit = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      };
      let dataUrl = `${apiUrl}/subscription/current`;
      fetch(dataUrl, requestOptions)
          .then(service.config.afterFetch)
          .then((response) => {
            return response.json()
          })
          .then(result => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
    });
  }

  list(query): Promise<SubscriptionList> {

    let service = this;
    const t = (phrase) => { return phrase};
    let dataUrl = `${apiUrl}/subscription/list`;
    return new Promise((resolve, reject) => {
      fetch(dataUrl, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(query)
      })
          .then(service.config.afterFetch)
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              case 403:
                throw new Error(t(phrases.Errors.Unauthorized));
              default:
                throw new Error(t('Could not fetch api tokens'));
            }
          })
          .then(function (response) {
            // let expectedResponseFields = [
            //   'error',
            //   'meta',
            //   'comms_units'
            // ];
            //
            // let isValidResponse = !expectedResponseFields.map((field) => {
            //   return Object.prototype.hasOwnProperty.call(response, field)
            // }).includes(false);
            //
            // if (!isValidResponse){
            //   reject(phrases.Errors.UnExpectedResponse);
            // }
            if (response.hasOwnProperty('error') && response.error != null) {
              reject(new Error(t(response.error)));
            } else {
              //validate response
              if (!Array.isArray(response.api_tokens)) {
                reject(new Error(t(phrases.Errors.UnExpectedResponse)));
              }
              let output = new SubscriptionList();
              output.data = [];
              for (let i = 0; i < response.api_tokens.length; i++) {
                output.data[i] = new Subscription(response.api_tokens[i])
              }
              output.meta = response.meta;
              resolve(output);
            }
          })
          .catch((error) => {
            reject(new Error(t(error)));
          })

    });


  }

  upgrade(subscriptionId, upgradeToTypeId) {

    let service = this;
    //const {t} = useTranslation();
    //const t = (phrase) => { return phrase};
    return new Promise((resolve, reject) => {

      const requestOptions: RequestInit = {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          subscription_id: subscriptionId,
          upgrade_to_type_id: upgradeToTypeId
        }),
      };
      let dataUrl = `${apiUrl}/subscription/upgrade`;
      fetch(dataUrl, requestOptions)
          .then(service.config.afterFetch)
          .then((response) => {
            return response.json()
          })
          .then(result => {
            if (result?.data !== undefined){
              for (let subscriptionIndex in result?.data){
                if (!Object.prototype.hasOwnProperty.call(result?.data, subscriptionIndex)){ continue; }
                if (result?.data[subscriptionIndex]?.active_until === undefined){ continue; }
                result.data[subscriptionIndex].active_until = parseISO(result?.data[subscriptionIndex]?.active_until)
              }
            }
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
    });
  }


  verify() {

    let service = this;
    //const {t} = useTranslation();
    //const t = (phrase) => { return phrase};
    return new Promise((resolve, reject) => {

      const requestOptions: RequestInit = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      };
      let dataUrl = `${apiUrl}/subscription/verify`;
      fetch(dataUrl, requestOptions)
          .then(service.config.afterFetch)
          .then((response) => {
            return response.json()
          })
          .then(result => {
            if (result?.data !== undefined){
              for (let subscriptionIndex in result?.data){
                if (!Object.prototype.hasOwnProperty.call(result?.data, subscriptionIndex)){ continue; }
                if (result?.data[subscriptionIndex]?.active_until === undefined){ continue; }
                result.data[subscriptionIndex].active_until = parseISO(result?.data[subscriptionIndex]?.active_until)
              }
            }
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
    });
  }
  isRoutePermitted(url, parameters, method = "get") {

    let service = this;
    //const {t} = useTranslation();
    //const t = (phrase) => { return phrase};
    return new Promise((resolve, reject) => {

      const requestOptions: RequestInit = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      };
      let dataUrl = `${apiUrl}/subscription/is_route_permitted`;
      fetch(dataUrl, requestOptions)
          .then(service.config.afterFetch)
          .then((response) => {
            return response.json()
          })
          .then(result => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
    });
  }

}

export {SubscriptionService, Subscription, SubscriptionList, SubscriptionTypeGroupNames, SubscriptionTypeNames};
