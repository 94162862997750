import {array} from "yup";
import DataServiceResults from "./DataServiceResults";
import DataQuery from "./DataQuery";
import md5 from "md5";
import {addDays, format, formatRFC3339, parseISO} from "date-fns";
/**
 * Copyright © Tony Schirmer All rights reserved.
 * See LICENSE.txt for license details.
 */


// eslint-disable-next-line
class DeviceDataCacheStorage {

  //data is a memory storage for everything within the window
  //when the user refreshes this will need to be grabbed again
  //however, we may change this overtime to store into the localstorage
  //example state is:
  //this.state['BRI547']['20120602']['OS10001'][] => { time: 12314654698, value: 24.0 }
  public state: object;

  //queries used to fill state, with meta results stored in teh following format:
  //this.queryResults['{installId: BRI547, from: \'2023-11-05T00:00:000Z\'}'] = {statusCode: 200}
  public queryResults: object;

  public timestampsCache : object;



  public timeZoneCache : object;
  public ControllerSerialNumbersCache : object;
  public ChartData : object;

  reset(){
    this.state = {};
    return this.state;
  }
}

export default DeviceDataCacheStorage;
