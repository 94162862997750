import {createSlice, current } from '@reduxjs/toolkit';
import {objFromArray} from "../utils/obj-from-array";
//import { current } from 'immer';
const initialState = {
    eventLogs: {
        byQuery: {},
        byId: {},
    }
};

export const slice = createSlice({
    name: 'deviceEventLog',
    initialState,
    reducers: {
        listDeviceEventLogs(state, action) {
            state.eventLogs.byQuery = {...state.eventLogs.byQuery, [JSON.stringify(action.payload.args)]: action.payload.result}
            for (let eventLog of action.payload.result.data) {
                state.eventLogs.byId[eventLog.id] = eventLog;
            }
        },
        getDeviceEventLog(state, action) {
            let eventLog = action.payload;
            state.eventLogs.byId = {...state.eventLogs.byId, [eventLog.id]: eventLog}
            //todo: create pidgeon hole cache for queries
            state.eventLogs.byQuery = [];
        },
    }
});

export const {reducer} = slice;