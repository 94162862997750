import {createContext, useEffect, useMemo, useReducer} from 'react';
import PropTypes from 'prop-types';
import DataProvider from "../data";
import {useRouter} from "next/router";


var ActionType;
(function (ActionType) {
    ActionType['INITIALIZE'] = 'INITIALIZE';
    ActionType['VERIFY'] = 'VERIFY';
    // ActionType['CANCEL'] = 'CANCEL';
})(ActionType || (ActionType = {}));

const initialState = {
    isSubscribed: false,
    isInitialized: false,
    subscriptions: null,
    lastAction: null
};

const handlers = {
    INITIALIZE: (state, action) => {
        const {subscriptions} = action.payload;

        return {
            ...state,
            isInitialized: true,
            isSubscribed: true,
            subscriptions,
            lastAction: ActionType.INITIALIZE
        };
    },
    VERIFY: (state, action) => {
        const {subscriptions} = action.payload;

        return {
            ...state,
            isSubscribed: true,
            subscriptions,
            lastAction: ActionType.VERIFY
        };
    },
    // CANCEL: (state) => ({
    //     ...state,
    //     isSubscribed: false,
    //     subscriptions: null,
    //     lastAction: ActionType.CANCEL
    // }),
};

const reducer = (state, action) => (handlers[action.type]
    ? handlers[action.type](state, action)
    : state);

export const SubscriptionContext = createContext({
    ...initialState,
    platform: 'CQSolaSubscription',
    verify: () => Promise.resolve(),
    // cancel: () => Promise.resolve(),
    //isRoutePermitted: () =>  Promise.resolve()
});

export const SubscriptionProvider = (props) => {
    const {children} = props;
    const [state, dispatch] = useReducer(reducer, initialState);
    const router = useRouter();
    const dataProvider = useMemo(() => {return new DataProvider({router: router})}, [router]);
    useEffect(() => {
        const initialize = async () => {
            try {

                const subscriptionsResponse = await dataProvider.Subscription().verify();
                let subscriptions = (subscriptionsResponse?.data) ?? [];
                /** @var dataProvider DataProvider */
                dispatch({
                    type: ActionType.INITIALIZE,
                    payload: {
                        //isSubscribed: subscription.is_active,
                        subscriptions
                    }
                });
            } catch (err) {
                console.error(err);
                dispatch({
                    type: ActionType.INITIALIZE,
                    payload: {
                        subscriptions: null
                    }
                });
            }
        };

        initialize();
    }, [dataProvider]);

    const verify = async () => {

        //let dataService = useDataService()
        //let dataProvider = dataService.getDataProvider();
        const subscriptionsResponse = await dataProvider.Subscription().verify();
        let subscriptions = (subscriptionsResponse?.data) ?? [];
        //todo: deal with error in response

        // let redirect = null;
        // let loginCompleted = false;
        // switch (loginResponse.redirect) {
        //     //the api is calling for a redirect, so the submitted cookies
        //     //need a 2fa verification, and the api gives the route relative to the backend.
        //     case '/v1/login/verify':
        //         redirect = '/authentication/verify-code';
        //         loginCompleted = false;
        //         break;
        //     default:
        //         loginCompleted = true;
        //         break;
        // }
        // loginResponse.redirect = redirect;
        //if (loginCompleted) {
        //     const subscription = await dataProvider.Subscription().getCurrentSubscription()
            dispatch({
                type: ActionType.VERIFY,
                payload: {
                    subscriptions
                }
            });
        //}
        /** @var dataProvider DataProvider */
        //const user = await dataProvider.User().getCurrentUser()
        //
        //   const {accessToken} = await authApi.login({email, password});
        //   const user = await authApi.me({accessToken});

        // localStorage.setItem('accessToken', accessToken);

        // dispatch({
        //     type: ActionType.LOGINSTART,
        //     payload: {
        //         loginResponse
        //     }
        // });

        return subscriptions;
    };

    // const cancel = async () => {
    //     let dataProvider = new DataProvider(props)
    //     await dataProvider.Subscription().Cancel()
    //     dispatch({type: ActionType.CANCEL});
    // };
    // const isRoutePermitted = async (route) => {
    //     let dataProvider = new DataProvider(props)
    //
    //     const subscriptionRoutes = {
    //         '/dashboard/analytics': {
    //             requires: [
    //                 {
    //                     typeGroup: 'data',
    //                     typeName: [
    //                         'basic',
    //                         'enterprise',
    //                         'evaluation'
    //                     ]
    //                 }
    //             ]
    //         },
    //         '/support/*': {
    //             requires: [
    //                 {
    //                     typeGroup: 'support',
    //                     typeName: [
    //                         'premium'
    //                     ]
    //                 }
    //             ],
    //             redirect: 'account/plans/support'
    //         }
    //     };
    //     if (subscriptionRoutes?.[route] !== undefined && Array.isArray(subscriptionRoutes?.[route]?.requires)){
    //
    //         let subscriptions = state.subscriptions;
    //         //check requirements
    //         let routeRequirementFulfilled = {};
    //         for (let requirementIndex in subscriptionRoutes[route].requires) {
    //             if (!Object.prototype.hasOwnProperty.call(subscriptionRoutes[route].requires, requirementIndex)) { continue; }
    //
    //             routeRequirementFulfilled[requirementIndex] = false;
    //             let requirement = subscriptionRoutes[route].requires[requirementIndex]
    //
    //             let inTypeGroupName = (subscriptions.findIndex(subscription => {
    //                 return subscription?.['type_group'] === requirement?.typeGroup;
    //             }) > -1);
    //
    //             let inTypeName = (subscriptions.findIndex(subscription => {
    //                 let typeName = (subscription?.['type_name'] !== undefined && typeof subscription?.['type_name'] === "string")? subscription?.['type_name'].toLowerCase(): '';
    //                 return Array.isArray(requirement?.typeName) && requirement?.typeName.includes(typeName)
    //             }) > -1);
    //
    //             if (inTypeGroupName && inTypeName){
    //                 routeRequirementFulfilled[requirementIndex] = true;
    //             }
    //         }
    //         let allRequirementsSatisfied = true;
    //         for (let requirementFulfilledKey in routeRequirementFulfilled){
    //             if (!Object.prototype.hasOwnProperty.call(routeRequirementFulfilled, requirementFulfilledKey)) { continue; }
    //             let requirementFulfilled = routeRequirementFulfilled[requirementFulfilledKey];
    //             if (requirementFulfilled === false) {
    //                 allRequirementsSatisfied = false;
    //                 break;
    //             }
    //         }
    //         return allRequirementsSatisfied;
    //
    //     }
    //     //route authorisation per available subscriptions
    //     //this is reflected on the server side as well,
    //     //just for removing ui elements
    //     return true;
    //
    //     // return false;//await dataProvider.Subscription().isRoutePermitted(route)
    // };

    return (
        <SubscriptionContext.Provider
            value={{
                ...state,
                platform: 'CQSolaAuth',
                verify,
                // cancel,
                //isRoutePermitted
            }}
        >
            {children}
        </SubscriptionContext.Provider>
    );
};

SubscriptionProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export const SubscriptionConsumer = SubscriptionContext.Consumer;
