/**
 * Copyright © Tony Schirmer All rights reserved.
 * See LICENSE.txt for license details.
 */
import {slice} from "../slices/session";
import DataProvider from "../data";
import NotFoundError from "../data/errors/NotFoundError";

export const getCurrentUser = () => async (dispatch) => {

    const dataProvider = new DataProvider({});
    const user = await dataProvider.User().getCurrentUser()

    dispatch(slice.actions.getCurrentUser(user));
};

export const saveCurrentUser = (
    firstName,
    lastName,
    email,
    countryCode,
    mobileNumber,
    timeZone,
    recaptchaCode) => async (dispatch) => {

    const dataProvider = new DataProvider({});
    const user = await dataProvider.User().saveCurrentUser(
        firstName,
        lastName,
        email,
        countryCode,
        mobileNumber,
        timeZone
    );
    dispatch(slice.actions.getCurrentUser(user));
};


export const getCurrentAccount = () => async (dispatch) => {
    const dataProvider = new DataProvider({});
    const account = await dataProvider.Account().getCurrentAccount()

    dispatch(slice.actions.getCurrentAccount(account));
};

export const getCurrentSessionContext = () => async (dispatch) => {
    const dataProvider = new DataProvider({});
    const sessionContext = await dataProvider.Session().getSessionContext();

    dispatch(slice.actions.getCurrentSessionContext(sessionContext));
};


export const saveCurrentAccount = (
    name,
    legalName,
    primaryEmail,
    recaptchaCode) => async (dispatch) => {

    const dataProvider = new DataProvider({});
    const account = await dataProvider.Account().saveCurrentAccount(
        name,
        legalName,
        primaryEmail
    );
    dispatch(slice.actions.getCurrentAccount(account));
};


export const deleteCurrentAccountStart = (currentPassword, recaptchaCode) => async (dispatch) => {
    const dataProvider = new DataProvider({});
    await dataProvider.Account().deleteCurrentAccountStart(currentPassword, recaptchaCode)
    const accountDeleteRequest =  await dataProvider.Account().deleteCurrentAccountStatus();
    dispatch(slice.actions.deleteCurrentAccountStart(accountDeleteRequest));
};


export const deleteCurrentAccountStatus = () => async (dispatch) => {
    const dataProvider = new DataProvider({});
    let accountDeleteRequest = {};
    try {
        accountDeleteRequest = await dataProvider.Account().deleteCurrentAccountStatus()
    }catch(err){
        if (err instanceof NotFoundError) {
            //ignore
        }
        else {
            throw err;
        }
    }

    dispatch(slice.actions.deleteCurrentAccountStatus(accountDeleteRequest));
};

export const deleteCurrentAccountCancel = (recaptchaCode) => async (dispatch) => {
    const dataProvider = new DataProvider({});
    const response = await dataProvider.Account().deleteCurrentAccountCancel(recaptchaCode)

    dispatch(slice.actions.deleteCurrentAccountCancel(response));
};
