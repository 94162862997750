import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    apiTokens: {
        byQuery: {},
        byId: {},
    }
};

export const slice = createSlice({
    name: 'apiToken',
    initialState,
    reducers: {
        listApiTokens(state, action) {

            state.apiTokens.byQuery = {...state.apiTokens.byQuery, [action.payload.query]: action.payload.result}
            //state.apiTokens.byQuery[action.payload.query] = action.payload.result;
            for (let apiToken of action.payload.result.data){
                state.apiTokens.byId = {...state.apiTokens.byId, [apiToken.id]: apiToken }
            }
        },
        getApiToken(state, action) {
            let apiToken = action.payload;
            state.apiTokens.byId = {...state.apiTokens.byId, [apiToken.id]: apiToken }
            //todo: create pidgeon hole cache for queries
            state.apiTokens.byQuery = [];
        },
        saveApiToken(state, action) {
            let apiToken = action.payload;
            state.apiTokens.byId = {...state.apiTokens.byId, [apiToken.id]: apiToken }
            //todo: create pidgeon hole cache for queries
            state.apiTokens.byQuery = [];
        },
        deleteApiToken(state, action) {
            let apiToken = action.payload;


            //state.apiTokens.byId = {...state.apiTokens.byId, apiToken.id]: apiToken }

            delete state.apiTokens.byId[apiToken.id];
            //todo: create pidgeon hole cache for queries
            state.apiTokens.byQuery = [];
        },
    }
});

export const {reducer} = slice;