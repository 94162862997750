/**
 * Copyright © Tony Schirmer All rights reserved.
 * See LICENSE.txt for license details.
 */
import {dataServiceConfig} from '../../config';
import phrases from "../phrases"
import Query from "../Query"
import NotFoundError from "../errors/NotFoundError";
import {pathHasPrefix} from "next/dist/shared/lib/router/utils/path-has-prefix";
import { parseISO } from 'date-fns';

class StripeConfig {
    publishable_key: '';
    subscriptions: []; // {id: "00271f66-efee-40ce-85ac-2150c50d3734", name: "name"}
    //prices: {};

    constructor(obj) {
        obj && Object.assign(this, obj);
    }
}

//
// class AccountList {
//     data: Array<Account>;
//     meta: {
//         total: 0,
//         page: 0,
//         page_size: 0
//     };
// }
//
//
// class AccountInvite {
//
//     id: '';
//     email: '';
//     status: '';
//     invited_by: '';
//     created_at: '';
//
//   statusOptions: {
//     unclaimed: 'Unclaimed'
//   };
//
//   constructor(obj) {
//     obj && Object.assign(this, obj);
//   }
// }

const apiUrl = dataServiceConfig.url;


export default class PaymentService {
  private config: any;
  private errorHandlers: any;

  constructor(config) {
    if (config == null) {
      config = {};
    }

    this.config = config;
    if (Object.prototype.hasOwnProperty.call(config, '=errorHandlers')){
      this.errorHandlers = config.errorHandlers;
    }
    else {
      this.errorHandlers = {};
    }
    
  }

  stripeConfig() : Promise<StripeConfig>{

    //const {t} = useTranslation();
    const t = (phrase) => {
        return phrase
    };
    let service = this;
    return new Promise((resolve, reject) => {

        let dataUrl = `${apiUrl}/payments/stripe/config`;
        fetch(dataUrl, {
            method: 'get',
            credentials: 'include',
        })
            .then(service.config.afterFetch)
            .then((response) => {
                switch (response.status) {
                    case 200:
                        return response.json();
                    case 403:
                        throw new Error(t(phrases.Errors.Unauthorized));
                    default:
                        throw new Error(t('Could not fetch current stripe payment config'));
                }
            })
            .then(function (response) {
                if (Object.prototype.hasOwnProperty.call(response, 'error') && response.error != null) {
                    reject(new Error(response.error));
                } else {
                    resolve(new StripeConfig(response.data));
                }
            })
            .catch((error) => {
                reject(new Error(error));
            })

    });
  }
    stripePortalLink() : Promise<void>{

        //const {t} = useTranslation();
        const t = (phrase) => {
            return phrase
        };
        let service = this;
        return new Promise((resolve, reject) => {

            let dataUrl = `${apiUrl}/payments/stripe/customer_portal`;
            fetch(dataUrl, {
                method: 'post',
                credentials: 'include',
            })
                .then(service.config.afterFetch)
                .then((response) => {
                    switch (response.status) {
                        case 200:
                        case 303:
                        case 302:
                            return response.json();
                        case 403:
                            throw new Error(t(phrases.Errors.Unauthorized));
                        default:
                            throw new Error(t('Could not fetch current stripe customer portal link'));
                    }
                })
                .then(function (response) {
                    if (Object.prototype.hasOwnProperty.call(response, 'error') && response.error != null) {
                        reject(new Error(response.error));
                    } else {
                        resolve(response.redirect);
                    }
                })
                .catch((error) => {
                    reject(new Error(error));
                })

        });
    }

    stripeSubscriptionUpgrade(currentSubscriptionId, upgradeToSubscriptionTypeId){
        let service = this;

        //const {t} = useTranslation();
        const t = (phrase) => {
            return phrase
        };
        return new Promise<void>((resolve, reject) => {

            let dataUrl = `${apiUrl}/payments/stripe/upgrade_subscription`;
            fetch(dataUrl, {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({
                    subscription_id: currentSubscriptionId,
                    new_subscription_type_id: upgradeToSubscriptionTypeId
                })
            })
                .then(service.config.afterFetch)
                .then((response) => {
                    if (Object.prototype.hasOwnProperty.call(service.errorHandlers, response.status) && typeof service.errorHandlers[response.status] === 'function') {
                        this.errorHandlers[response.status]();
                    }
                    return response;
                })
                .then((response) => {
                    switch (response.status) {
                        case 200:
                            return response.json();
                        case 403:
                            throw new Error(t('Unauthorised to upgrade subscription for account'));
                        default:
                            throw new Error(t('Could not upgrade subscription for account'));
                    }
                })
                .then(function (response) {
                    if (Object.prototype.hasOwnProperty.call(response, 'error') && response.error != null) {
                        reject(new Error(t(response.error)));
                    } else {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(new Error(t(error)));
                })

        });
    }

    stripeCheckoutCreate(subscriptionTypeId){
        let service = this;

        //const {t} = useTranslation();
        const t = (phrase) => {
            return phrase
        };
        return new Promise<void>((resolve, reject) => {

            let dataUrl = `${apiUrl}/payments/stripe/checkout_create`;
            fetch(dataUrl, {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({
                    subscription_type_id: subscriptionTypeId
                })
            })
                .then(service.config.afterFetch)
                .then((response) => {
                    if (Object.prototype.hasOwnProperty.call(service.errorHandlers, response.status) && typeof service.errorHandlers[response.status] === 'function') {
                        this.errorHandlers[response.status]();
                    }
                    return response;
                })
                .then((response) => {
                    switch (response.status) {
                        case 200:
                            return response.json();
                        case 403:
                            throw new Error(t('Unauthorised to start checkout for account'));
                        default:
                            throw new Error(t('Could not start checkout for account'));
                    }
                })
                .then(function (response) {
                    if (Object.prototype.hasOwnProperty.call(response, 'error') && response.error != null) {
                        reject(new Error(t(response.error)));
                    } else {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(new Error(t(error)));
                })

        });
  }


}

export {PaymentService, StripeConfig};
