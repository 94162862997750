/**
 * Copyright © Tony Schirmer All rights reserved.
 * See LICENSE.txt for license details.
 */
import {dataServiceConfig} from '../../config';
import phrases from "../phrases"
import Query from "../Query"
import NotFoundError from "../errors/NotFoundError";
import {pathHasPrefix} from "next/dist/shared/lib/router/utils/path-has-prefix";
import { parseISO } from 'date-fns';

class Account {
    name: '';
    legal_name: '';
    primary_email: '';
    lookup_token: '';
    is_current_user_owner: false;
    created_at: '1970-01-01T00:00:00.000Z';
    updated_at: '1970-01-01T00:00:00.000Z';

    constructor(obj) {
        obj && Object.assign(this, obj);
    }

}

class AccountList {
    data: Array<Account>;
    meta: {
        total: 0,
        page: 0,
        page_size: 0
    };
}


class AccountInvite {

    id: '';
    email: '';
    status: '';
    invited_by: '';
    created_at: '';

  statusOptions: {
    unclaimed: 'Unclaimed'
  };

  constructor(obj) {
    obj && Object.assign(this, obj);
  }
}

const apiUrl = dataServiceConfig.url;


export default class AccountService {
  private config: any;
  private errorHandlers: any;

  constructor(config) {
    if (config == null) {
      config = {};
    }

    this.config = config;
    if (Object.prototype.hasOwnProperty.call(config, '=errorHandlers')){
      this.errorHandlers = config.errorHandlers;
    }
    else {
      this.errorHandlers = {};
    }
    
  }


  deleteCurrentAccountStatus() {

      //const {t} = useTranslation();
      const t = (phrase) => {
          return phrase
      };
      let service = this;
      return new Promise((resolve, reject) => {

          let dataUrl = `${apiUrl}/account/delete/status`;
          fetch(dataUrl, {
              method: 'GET',
              credentials: 'include',
          })
              .then(service.config.afterFetch)
              .then((response) => {
            switch (response.status) {
              case 200:
              case 404:
                return response.json();
              case 403:
                throw new Error(t('Unauthorised to delete account'));
              default:
                throw new Error(t('Could not delete account'));
            }
          })
          .then(function (response) {
            if (Object.prototype.hasOwnProperty.call(response, 'error') && response.error != null) {
                if (Object.prototype.hasOwnProperty.call(response.error, 'not_found') && response.error.not_found != null) {
                    reject(new NotFoundError(t(response.error.not_found)));
                    return;
                }
                else {
                    reject(new Error(t(JSON.stringify(response.error))));
                    return;
                }

            } else {
              //response.delete_at = moment(response.delete_at)
              // let parseISO = require('date-fns/parseISO')
              response.delete_at = parseISO(response.delete_at)
              resolve(response);
            }
          })
          .catch((error) => {
            reject(new Error(t(error)));
          })

    });

  }


  deleteCurrentAccountCancel(reCaptchaToken) {
      let service = this;

      //const {t} = useTranslation();
      const t = (phrase) => {
          return phrase
      };
      return new Promise<void>((resolve, reject) => {

          let dataUrl = `${apiUrl}/account/delete/cancel`;
          fetch(dataUrl, {
              method: 'POST',
              credentials: 'include',
              body: JSON.stringify({
                  recaptcha_token: reCaptchaToken
              })
          })
          .then(service.config.afterFetch)
          .then((response) => {
            if (Object.prototype.hasOwnProperty.call(service.errorHandlers, response.status) && typeof service.errorHandlers[response.status] === 'function') {
              this.errorHandlers[response.status]();
            }
            return response;
          })
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              case 403:
                throw new Error(t('Unauthorised to delete account'));
              default:
                throw new Error(t('Could not cancel delete request for account'));
            }
          })
          .then(function (response) {
            if (Object.prototype.hasOwnProperty.call(response, 'error') && response.error != null) {
              reject(new Error(t(response.error)));
            } else {
              resolve();
            }
          })
          .catch((error) => {
            reject(new Error(t(error)));
          })

    });

  }


  deleteCurrentAccountStart(currentPassword, reCaptchaToken) {
      let service = this;

      //const {t} = useTranslation();
      const t = (phrase) => {
          return phrase
      };
      return new Promise<void>((resolve, reject) => {

          let dataUrl = `${apiUrl}/account/delete/start`;
          fetch(dataUrl, {
              method: 'POST',
              credentials: 'include',
              body: JSON.stringify({
                  current_password: currentPassword,
                  recaptcha_token: reCaptchaToken
              })
      })
          .then(service.config.afterFetch)
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              case 403:
                throw new Error('Unauthorised to delete account');
              default:
                throw new Error('Could not delete account');
            }
          })
          .then(function (response) {
            if (Object.prototype.hasOwnProperty.call(response, 'error') && response.error != null) {
              reject(new Error(response.error));
            } else {
              resolve();
            }
          })
          .catch((error) => {
            reject(new Error(error));
          })

    });

  }


  saveCurrentAccount(name, legalName, primaryEmail): Promise<Account> {
      let service = this;

      //const {t} = useTranslation();
      const t = (phrase) => {
          return phrase
      };
      return new Promise((resolve, reject) => {

          let accountData = {
              name: name,
              legal_name: legalName,
              primary_email: primaryEmail
          };
          let dataUrl = `${apiUrl}/account/self`;
          fetch(dataUrl, {
              method: 'POST',
        credentials: 'include',
        body: JSON.stringify({account: accountData})
      })
          .then(service.config.afterFetch)
          .then((response) => {
            switch (response.status) {
              case 200:
              case 400:
                return response.json();
              case 403:
                  throw new Error(t(phrases.Errors.Unauthorized));
              default:
                  throw new Error(t('Could not fetch current user details'));
            }
          })
          .then(function (response) {
            if (Object.prototype.hasOwnProperty.call(response, 'error') && response.error != null) {
              reject(new Error(response.error));
            } else {
              resolve(new Account(response.account));
            }
          })
          .catch((error) => {
            reject(new Error(error));
          })

    });

  }


  getCurrentAccount(): Promise<Account> {

      //const {t} = useTranslation();
      const t = (phrase) => {
          return phrase
      };
      let service = this;
      return new Promise((resolve, reject) => {

          let dataUrl = `${apiUrl}/account/self`;
          fetch(dataUrl, {
              method: 'get',
              credentials: 'include',
          })
              .then(service.config.afterFetch)
              .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              case 403:
                throw new Error(t(phrases.Errors.Unauthorized));
              default:
                throw new Error(t('Could not fetch current user details'));
            }
          })
          .then(function (response) {
            if (Object.prototype.hasOwnProperty.call(response, 'error') && response.error != null) {
              reject(new Error(response.error));
            } else {
                resolve(new Account(response.account));
            }
          })
          .catch((error) => {
            reject(new Error(error));
          })

    });

  }

  //
  // static saveCurrentAccount = function (
  //   firstName,
  //   lastName,
  //   email,
  //   countryCode,
  //   mobileNumber,
  //   timeZone
  // ): Promise<User> {
  //   let userData = {
  //     email: email,
  //     first_name: firstName,
  //     last_name: lastName,
  //     mobile_number: mobileNumber,
  //     country_code: countryCode,
  //     timezone: timeZone,
  //   };
  //   let dataUrl = `${apiUrl}/user/self`;
  //   return new Promise((resolve, reject) => {
  //     fetch(dataUrl, {
  //       method: 'POST',
  //       credentials: 'include',
  //       body: JSON.stringify({ user: userData })
  //     })
  //       .then( (response) => {
  //         switch (response.status){
  //           case 200:
  //             return response.json();
  //           case 403:
  //             throw new Error('Unauthorised to Access Resource');
  //           default:
  //             throw new Error('Could not save current user details');
  //         }
  //       })
  //       .then(function (userSelfResponse) {
  //         if (userSelfResponse.hasOwnProperty('error') && userSelfResponse.error != null) {
  //           reject(new Error(userSelfResponse.error));
  //         } else {
  //           resolve(new User(userSelfResponse.user));
  //         }
  //       })
  //       .catch((error) => {
  //         reject(new Error(error));
  //       })
  //
  //   });
  //
  // }
  inviteToJoinAccount(email) {
      let service = this;

      //const {t} = useTranslation();
      const t = (phrase) => {
          return phrase
      };
      return new Promise<void>((resolve, reject) => {
          const requestOptions: RequestInit = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              credentials: 'include',
              body: JSON.stringify({email: email})
          };
          let dataUrl = `${apiUrl}/account/invite/send`;
      fetch(dataUrl, requestOptions)
          .then(service.config.afterFetch)
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              case 403:
                throw new Error(t('Unauthorised to Access Resource'));
              case 404:
                throw new Error(t('Could not verify, please check and try again'));
              case 409:
                throw new Error(t('Email Address already has outstanding invite, revoke to re-send'));
              default:
                throw new Error(t(phrases.Errors.ServerUnavailable));
            }
          })
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
    });
  };


  removeUserFromAccount(userEmail) {
      let service = this;

      //const {t} = useTranslation();
      const t = (phrase) => {
          return phrase
      };
      return new Promise<void>((resolve, reject) => {
          const requestOptions: RequestInit = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              credentials: 'include',
              body: JSON.stringify({email: userEmail})
          };
          let dataUrl = `${apiUrl}/account/user/remove`;
          fetch(dataUrl, requestOptions)
              .then(service.config.afterFetch)
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              case 403:
                throw new Error(t('Unauthorised to Access Resource'));
              case 404:
                throw new Error(t('Could not verify, please check and try again'));
              default:
                throw new Error(t(phrases.Errors.ServerUnavailable));
            }
          })
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
    });
  };


  revokeInviteToJoinAccount(inviteId) {
      let service = this;

      //const {t} = useTranslation();
      const t = (phrase) => {
          return phrase
      };
      return new Promise<void>((resolve, reject) => {
          const requestOptions: RequestInit = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              credentials: 'include',
              body: JSON.stringify({invite_id: inviteId})
          };
          let dataUrl = `${apiUrl}/account/invite/revoke`;
      fetch(dataUrl, requestOptions)
          .then(service.config.afterFetch)
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              case 404:
                throw new Error(t('Could not verify, please check and try again'));
              case 403:
                throw new Error(t(phrases.Errors.Unauthorized));
              case 409:
                throw new Error(t('Email Address already has outstanding invite, revoke to re-send'));
              default:
                throw new Error(t(phrases.Errors.ServerUnavailable));
            }
          })
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
    });
  };

  acceptInviteToJoinAccount(token) {
      let service = this;

      //const {t} = useTranslation();
      const t = (phrase) => {
          return phrase
      };
      return new Promise((resolve, reject) => {
          const requestOptions: RequestInit = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              credentials: 'include',
              body: JSON.stringify({token: token})
          };
          let dataUrl = `${apiUrl}/account/invite/accept`;
      fetch(dataUrl, requestOptions)
          .then(service.config.afterFetch)
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              case 404:
                throw new Error(t('Invite token not found, please check and try again'));
              case 403:
                throw new Error(t(phrases.Errors.Unauthorized));
              default:
                throw new Error(t(phrases.Errors.ServerUnavailable));
            }
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
    });
  }


  transferAccountOwnership(transferToEmail, currentPassword, recaptchaToken) {
      let service = this;

      //const {t} = useTranslation();
      const t = (phrase) => {
          return phrase
      };
      return new Promise((resolve, reject) => {
          const requestOptions: RequestInit = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              credentials: 'include',
              body: JSON.stringify({
                  transfer_to_email: transferToEmail,
                  current_password: currentPassword,
                  recaptcha_token: recaptchaToken
              })
      };
      let dataUrl = `${apiUrl}/account/transfer`;
      let status = 0;
      fetch(dataUrl, requestOptions)
          .then(service.config.afterFetch)
          .then((response) => {
            status = response.status;
            switch (response.status) {
              case 403:
              case 200:
                return response.json();
              case 404:
                throw new Error(t('Not found, please check and try again'));
              default:
                throw new Error(t(phrases.Errors.ServerUnavailable));
            }
          })
          .then((response) => {
            let errorText = phrases.Errors.Unauthorized;
            switch (status) {
              case 403:
                  let errorText = '';
                  switch(true){
                      case response?.error?.unauthorized !== undefined:
                          errorText = response.error.unauthorized;
                          break;
                      case response?.error?.user_forbidden !== undefined:
                          errorText = response.error.user_forbidden;
                          break;
                      default:
                          errorText = phrases.Errors.Unauthorized;
                          break;
                  }

                //   if (
                //     Object.prototype.hasOwnProperty.call(response, 'error') !== false &&
                //     Object.prototype.hasOwnProperty.call(response.error, 'user_forbidden') !== false &&
                //     response.error.user_forbidden != null
                // ) {
                //   errorText = response.error.user_forbidden;
                // }
                throw new Error(t(errorText));
              default:
                break;
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
    });
  }

    /**
     *
     * @param {Query} query
     */
    listPendingInvitations(query: Query) {
        let service = this;

        //const {t} = useTranslation();
        const t = (phrase) => {
            return phrase
        };
        return new Promise((resolve, reject) => {
            let requestOptions: RequestInit = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(query)
            };
            let dataUrl = `${apiUrl}/account/invite/list`;
      fetch(dataUrl, requestOptions)
          .then(service.config.afterFetch)
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              case 404:
                throw new Error(t('Could not verify, please check and try again'));
              case 403:
                throw new Error(t(phrases.Errors.Unauthorized));
              default:
                throw new Error(t(phrases.Errors.ServerUnavailable));
            }
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
            // resolve({invites: [
            //   {id: "123", email: "test@test.com",   status:"Unclaimed", invited_by:"tony@test.com", created_at: now()},
            //   {id: "125", email: "tony@cqsola.com", status:"Revoked",   invited_by:"tony@test.com", created_at: now()},
            //   {id: "127", email: "tony@cqsola.com", status:"Expired",   invited_by:"tony@test.com", created_at: now()},
            //   {id: "129", email: "tony@cqsola.com", status:"Expired",   invited_by:"tony@test.com", created_at: now()},
            //   {id: "131", email: "tony@cqsola.com", status:"Expired",   invited_by:"tony@test.com", created_at: now()},
            //   ], meta: {total: 5}});
        });
    }

    async listAccountsForUser(query: Query): Promise<AccountList> {
        return new Promise((resolve, reject) => {
            let requestOptions: RequestInit = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(query)
            };
            let service = this;
            const t = (phrase) => {
                return phrase
            };
            let dataUrl = `${apiUrl}/account/list`;
            fetch(dataUrl, requestOptions)
                .then(service.config.afterFetch)
                .then((response) => {
                    switch (response.status) {
                        case 200:
                            return response.json();
                        case 404:
                            throw new Error(t('Could not verify, please check and try again'));
                        case 403:
                            throw new Error(t(phrases.Errors.Unauthorized));
                        default:
                            throw new Error(t(phrases.Errors.ServerUnavailable));
                    }
                })
                .then((response) => {
                    if (!Object.prototype.hasOwnProperty.call(response, 'accounts') ||
                        !Object.prototype.hasOwnProperty.call(response, 'error')) {
                        reject(new Error(t(phrases.Errors.UnExpectedResponse)));
                        return;
                    }
                    if (typeof response.error != "undefined" && response.error != null) {
                        reject(new Error(t(response.error)));
                        return;
                    }
                    let output = new AccountList();
                    output.data = [];
                    for (let i = 0; i < response.accounts.length; i++) {
                        output.data[i] = new Account(response.accounts[i])
                    }
                    output.meta = response.meta;
                    resolve(output);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        //
        // const t = (phrase) => { return phrase };
        // let requestOptions = { //: RequestInit =
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     credentials: 'include',
        // }
        // let dataUrl = `${apiUrl}/account/list`;
        // let response = await fetch(dataUrl, requestOptions);
        // //response = this.config.afterFetch(response);
        // let jsonResponse = null;
        // switch (response.status) {
        //     case 200:
        //         jsonResponse = await response.json();
        //         break;
        //     case 404:
        //         throw new Error(t('Could not verify, please check and try again'));
        //     case 403:
        //         throw new Error(t(phrases.Errors.Unauthorized));
        //     default:
        //         throw new Error(t(phrases.Errors.ServerUnavailable));
        // }
        // if (!Object.prototype.hasOwnProperty.call(jsonResponse, 'accounts') ||
        //     !Object.prototype.hasOwnProperty.call(jsonResponse, 'error')) {
        //     throw new Error(t(phrases.Errors.UnExpectedResponse))
        // }
        // if (typeof jsonResponse.error != "undefined" && jsonResponse.error != null){
        //     throw new Error(jsonResponse.error);
        // }
        // return jsonResponse.accounts;

    }

    /**
     *
     * @param {Query} query
     */
    listUsersInAccount(query) {
        //todo: add caching
        //let query = new Query({ filter: {page: page, page_size: pageSize} });
        let service = this;

        //const {t} = useTranslation();
      const t = (phrase) => {
          return phrase
      };
      return new Promise((resolve, reject) => {
          const requestOptions: RequestInit = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              credentials: 'include',
              body: JSON.stringify(query)
          };
          const dataUrl = `${apiUrl}/account/user/list`;
          fetch(dataUrl, requestOptions)
              .then(service.config.afterFetch)
              .then((response) => {
                switch (response.status) {
                  case 200:
                    return response.json();
                  case 404:
                    throw new Error(t('Could not verify, please check and try again'));
                  case 403:
                    throw new Error(t(phrases.Errors.Unauthorized));
                  default:
                    throw new Error(t(phrases.Errors.ServerUnavailable));
                }
              })
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
        });
  };


}

export {AccountService, Account, AccountInvite};
