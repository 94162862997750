/**
 * Copyright © Tony Schirmer All rights reserved.
 * See LICENSE.txt for license details.
 */
import {dataServiceConfig} from '../../config';
//import {useTranslation} from "react-i18next";
import phrases from "../phrases"
import NotFoundError from "../errors/NotFoundError";
class DeviceMetaData {


  serial_number: string;
  install_id: string;
  date: string;
  device_type: string;
  gps_coordinate_lat: string;
  gps_coordinate_long: string;
  timezone: string;
  timezone_offset_minutes: number;
  status: string;
  controller_serial_numbers: Array<string>;
  last_data_sync_at: Date;
  last_seen_at: Date;


  constructor(obj) {
    if (typeof obj?.last_data_sync_at === "string"){
      obj.last_data_sync_at = new Date(obj?.last_data_sync_at);
    }

    if (typeof obj?.last_seen_at === "string"){
      obj.last_seen_at = new Date(obj?.last_seen_at);
    }
    obj && Object.assign(this, obj);
  }
  ToNativeJsObject() : any {
    return Object.assign({}, this);
  }
}

class DeviceMetaDataList {
  data: Array<DeviceMetaData>;
  meta: {
    total: 0,
    page: 0,
    page_size: 0
  };

  ToNativeJsObject() : any {
    let output = {
      data: [],
      meta: {
        total: 0,
        page: 0,
        page_size: 0
      }
    };
    for (let i=0; i< this.data.length; i++) {
      output.data[i] = Object.assign({}, this.data[i]);
    }
    output.meta = Object.assign({}, this.meta);
    return output;
  }
}

const apiUrl = dataServiceConfig.url;
export default class DeviceMetaDataService {
  private config: any;

  constructor(config) {
    if (config == null) {
      config = {};
    }
    this.config = config;
  }

  get(installId, date): Promise<DeviceMetaData> {
    return this._get({
      install_id: installId,
      date: date
    })
  }



  private _get(query): Promise<DeviceMetaData> {
    let service = this;
    const t = (phrase) => { return phrase}; // useTranslation();
    return new Promise((resolve, reject) => {

      let dataUrl = `${apiUrl}/device_meta_data/get`;
      fetch(dataUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(query)
      })
          .then(service.config.afterFetch)
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              case 403:
                throw new Error(t(phrases.Errors.Unauthorized));
              case 404:
                reject(new NotFoundError(t('Could not get device meta data')));
                break;
              default:
                reject( new Error(t('Could not get device meta data')));
                break;
            }})
          .then(result => {
            resolve(new DeviceMetaData(result?.data));
          })
          .catch((error) => {
            reject(error);
          });
    });
  }


  // list(query): Promise<DeviceMetaDataList> {
  //
  //   let service = this;
  //   const t = (phrase) => { return phrase};
  //   let dataUrl = `${apiUrl}/device_meta_data/list`;
  //   return new Promise((resolve, reject) => {
  //     fetch(dataUrl, {
  //       method: 'POST',
  //       credentials: 'include',
  //       body: JSON.stringify(query)
  //     })
  //         .then(service.config.afterFetch)
  //         .then((response) => {
  //           switch (response.status) {
  //             case 200:
  //               return response.json();
  //             case 403:
  //               throw new Error(t(phrases.Errors.Unauthorized));
  //             default:
  //               throw new Error(t('Could not fetch device last_seens'));
  //           }
  //         })
  //         .then(function (response) {
  //           // let expectedResponseFields = [
  //           //   'error',
  //           //   'meta',
  //           //   'data'
  //           // ];
  //           //
  //           // let isValidResponse = !expectedResponseFields.map((field) => {
  //           //   return Object.prototype.hasOwnProperty.call(response, field)
  //           // }).includes(false);
  //           //
  //           // if (!isValidResponse){
  //           //   reject(phrases.Errors.UnExpectedResponse);
  //           // }
  //           if (response.hasOwnProperty('error') && response.error != null) {
  //             reject(new Error(t(response.error)));
  //           } else {
  //             //validate response
  //             if (!Array.isArray(response.data)) {
  //               reject(new Error(t(phrases.Errors.UnExpectedResponse)));
  //             }
  //             let output = new DeviceMetaDataList();
  //             output.data = [];
  //             for (let i = 0; i < response.data.length; i++) {
  //               output.data[i] = new DeviceMetaData(response.data[i])
  //             }
  //             output.meta = response.meta;
  //             resolve(output);
  //           }
  //         })
  //         .catch((error) => {
  //           reject(new Error(t(error)));
  //         })
  //
  //   });
  //
  //
  // }


}

export {DeviceMetaDataService, DeviceMetaData, DeviceMetaDataList};
