/**
 * Copyright © Tony Schirmer All rights reserved.
 * See LICENSE.txt for license details.
 */
// eslint-disable-next-line
class Query {
  static readonly QuerySortOrderDesc: string = "desc";
  static readonly QuerySortOrderAsc: string = "asc";

  filter: {
    query: string,
    from: Date,
    to: Date,
    page: number,
    pageSize: number
  };
  sort: {
    by: string,
    order: string,
  };

  constructor(obj) {
    if (obj?.filter?.page_size !== undefined){
      obj.filter.pageSize = obj.filter.page_size;
      delete obj.filter.page_size;
    }
    obj && Object.assign(this, obj);
  }

  toJSON() {

    let output :any = {};
    let includeFilter = !(
        this?.filter?.query === undefined &&
        this?.filter?.from === undefined &&
        this?.filter?.to === undefined &&
        this?.filter?.page === undefined &&
        this?.filter?.pageSize === undefined
    );
    if (includeFilter){
      output.filter = {};
      if (this?.filter?.query !== undefined) { output.filter.query = this?.filter?.query; }
      if (this?.filter?.from !== undefined) { output.filter.from = this?.filter?.from; }
      if (this?.filter?.to !== undefined) { output.filter.to = this?.filter?.to; }
      if (this?.filter?.page !== undefined) { output.filter.page = this?.filter?.page; }
      //important note the camelcase conversion here. The server backend expects page_size as the variable.
      if (this?.filter?.pageSize !== undefined) { output.filter.page_size = this?.filter?.pageSize; }
    }
    let includeSort = !(
        this?.sort?.by === undefined &&
        this?.sort?.order === undefined
    );
    if (includeSort){
      output.sort = {};
      if (this?.sort?.by !== undefined) { output.sort.by = this?.sort?.by; }
      if (this?.sort?.order !== undefined) { output.sort.order = this?.sort?.order; }
    }
    return output;
  }



  CheckSum(){
    const hashableObj = this.toJSON();
    return JSON.stringify(hashableObj);
  }
}

export default Query;
