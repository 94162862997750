/**
 * Copyright © Tony Schirmer All rights reserved.
 * See LICENSE.txt for license details.
 */
import {dataServiceConfig} from '../../config';
import {useTranslation} from "react-i18next";
import phrases from "../phrases"

class SessionContext {
  ip: '';

  constructor(obj) {
    obj && Object.assign(this, obj);
  }
}

class Session {
  expires_at: '1970-01-01T00:00:00.000Z';
  user_id: '';
  account_id: '';

  constructor(obj) {
    obj && Object.assign(this, obj);
  }

}

const apiUrl = dataServiceConfig.url;
export default class SessionService {
  private config: any;

  constructor(config) {
    if (config == null) {
      config = {};
    }
    this.config = config;
  }


  getSessionContext(): Promise<SessionContext> {
    let service = this;
    //const {t} = useTranslation();

    const t = (phrase) => { return phrase; };
    return new Promise((resolve, reject) => {
      let dataUrl = `${apiUrl}/session/context`;
      fetch(dataUrl, {
        method: 'get',
        credentials: 'include',
      })
          .then(service.config.afterFetch)
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              case 403:
                throw new Error(t(phrases.Errors.Unauthorized));
              default:
                throw new Error(t('Could not fetch current session details'));
            }
          })
          .then(function (response) {
            if (Object.prototype.hasOwnProperty.call(response, 'error') && response.error != null) {
              reject(new Error(t(response.error)));
            } else {
              //window.localStorage.setItem(userSelflocalStorageKey, JSON.stringify(userSelfResponse.user));
              resolve(new SessionContext(response.context));
            }
          })
          .catch((error) => {
            reject(new Error(t(error)));
          })

    });
  }

  getCurrentSession(): Promise<Session> {
    // let user = {
    //   username: '',
    //   email: '',
    //   first_name: '',
    //   last_name: '',
    //   mobile_number: '',
    //   is_active: true,
    //   created_at: '1970-01-01T00:00:00.000Z',
    //   updated_at: '1970-01-01T00:00:00.000Z',
    //   enable_2fa: false,
    //   country_code: '',
    //   timezone: '',
    // };

    let service = this;
    // const {t} = useTranslation();
    const t = (phrase) => {return phrase;}

    return new Promise((resolve, reject) => {
      // const userSelflocalStorageKey = 'user_self'
      // let userSelfData = window.localStorage.getItem(userSelflocalStorageKey);
      // if (userSelfData != null  ) {
      //   let userSelf = new User(JSON.parse(userSelfData));
      //   let sessionUserData = UserService.getCurrentSession();
      //   if (sessionUserData.email !== userSelf.email){
      //     window.localStorage.setItem(userSelflocalStorageKey, null);
      //   } else {
      //     resolve(userSelf);
      //     return;
      //   }
      // }
      let dataUrl = `${apiUrl}/session/current`;
      fetch(dataUrl, {
        method: 'get',
        credentials: 'include',
      })
          .then(service.config.afterFetch)
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              case 403:
                throw new Error(t(phrases.Errors.Unauthorized));
              default:
                throw new Error(t('Could not fetch current user details'));
            }
          })
          .then(function (response) {
            if (Object.prototype.hasOwnProperty.call(response, 'error') && response.error != null) {
              reject(new Error(t(response.error)));
            } else {
              //window.localStorage.setItem(userSelflocalStorageKey, JSON.stringify(userSelfResponse.user));
              resolve(new Session(response.session));
            }
          })
          .catch((error) => {
            reject(new Error(t(error)));
          })

    });

  }


}

export {SessionService, Session, SessionContext};
