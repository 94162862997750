/**
 * Copyright © Tony Schirmer All rights reserved.
 * See LICENSE.txt for license details.
 */
import {dataServiceConfig} from '../../config';
import {useTranslation} from "react-i18next";
import phrases from "../phrases"

class UserApiToken {


  id: '';
  name: '';
  restrict_to_ip: '';
  is_active: '';
  notes: '';
  updated_at: '';
  created_at: '';

  constructor(obj) {
    obj && Object.assign(this, obj);
  }
}

class UserApiTokenList {
  data: Array<UserApiToken>;
  meta: {
    total: 0,
    page: 0,
    page_size: 0
  };
}

const apiUrl = dataServiceConfig.url;
export default class UserApiTokenService {
  private config: any;

  constructor(config) {
    if (config == null) {
      config = {};
    }
    this.config = config;
  }


  get(userApiTokenId): Promise<UserApiToken> {
    let service = this;
    const t = (phrase) => { return phrase}; // useTranslation();
    return new Promise((resolve, reject) => {
      if (! (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(userApiTokenId))){
        reject(new Error("id is not a uuid"));
      }
      const requestOptions: RequestInit = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          id: userApiTokenId,
        })
      };
      let dataUrl = `${apiUrl}/user/api_token/get`;
      fetch(dataUrl, requestOptions)
          .then(service.config.afterFetch)
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              case 403:
                throw new Error(t(phrases.Errors.Unauthorized));
              default:
                throw new Error(t('Could not get api token'));
          }})
          .then(result => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
    });
  }

  save(userApiToken, currentPassword) {
    // remove user from local storage to log user out
    let service = this;
    const t = (phrase) => { return phrase};
    return new Promise((resolve, reject) => {
      const requestOptions: RequestInit = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          api_token: userApiToken,
          current_password: currentPassword
        })
      };
      let dataUrl = `${apiUrl}/user/api_token/save`;
      fetch(dataUrl, requestOptions)
          .then(service.config.afterFetch)
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              case 403:
                throw new Error(t(phrases.Errors.Unauthorized));
              default:
                throw new Error(t('Could not save api token'));
            }})
          .then(result => {
            if (Object.prototype.hasOwnProperty.call(result, 'error') &&
                result.error != null
            ) {
              console.error(result.error);
              reject(result.error);
              return;
            }
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
    });
  };

  list(query): Promise<UserApiTokenList> {

    let service = this;
    const t = (phrase) => { return phrase};
    let dataUrl = `${apiUrl}/user/api_token/list`;
    return new Promise((resolve, reject) => {
      fetch(dataUrl, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(query)
      })
          .then(service.config.afterFetch)
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              case 403:
                throw new Error(t(phrases.Errors.Unauthorized));
              default:
                throw new Error(t('Could not fetch api tokens'));
            }
          })
          .then(function (response) {
            // let expectedResponseFields = [
            //   'error',
            //   'meta',
            //   'comms_units'
            // ];
            //
            // let isValidResponse = !expectedResponseFields.map((field) => {
            //   return Object.prototype.hasOwnProperty.call(response, field)
            // }).includes(false);
            //
            // if (!isValidResponse){
            //   reject(phrases.Errors.UnExpectedResponse);
            // }
            if (response.hasOwnProperty('error') && response.error != null) {
              reject(new Error(t(response.error)));
            } else {
              //validate response
              if (!Array.isArray(response.api_tokens)) {
                reject(new Error(t(phrases.Errors.UnExpectedResponse)));
              }
              let output = new UserApiTokenList();
              output.data = [];
              for (let i = 0; i < response.api_tokens.length; i++) {
                output.data[i] = new UserApiToken(response.api_tokens[i])
              }
              output.meta = response.meta;
              resolve(output);
            }
          })
          .catch((error) => {
            reject(new Error(t(error)));
          })

    });


  }

  delete(userApiTokenId) {

    let service = this;
    //const {t} = useTranslation();
    const t = (phrase) => { return phrase};
    return new Promise((resolve, reject) => {
      const requestOptions: RequestInit = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          id: userApiTokenId,
        })
      };
      let dataUrl = `${apiUrl}/user/api_token/delete`;
      fetch(dataUrl, requestOptions)
          .then(service.config.afterFetch)
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              case 403:
                throw new Error(t(phrases.Errors.Unauthorized));
              default:
                throw new Error(t('Could not delete api token'));
            }})
          .then(result => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
    });
  }


}

export {UserApiTokenService, UserApiToken, UserApiTokenList};
