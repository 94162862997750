/**
 * Copyright © Tony Schirmer All rights reserved.
 * See LICENSE.txt for license details.
 */
import {dataServiceConfig} from '../../config';
import {useTranslation} from "react-i18next";
import phrases from "../phrases"
import DataQuery from "../DeviceDataService/DataQuery";
import {addDays, format, formatRFC3339, parseISO} from "date-fns";


class Controller {
  id: "";
  install_id: "";
  group_tags: "";
  time_zone: "";

  constructor(obj) {
    obj && Object.assign(this, obj);
  }

  ToNativeJsObject(): any {
    return  Object.assign({}, this);
  }
}

class ControllerList {
  data: Controller[];
  meta: {};

  ToNativeJsObject(): any {
    let output :any = {
      data: [],
      meta: {
        total: 0,
        page: 0,
        page_size: 0
      }
    };
    for (let i=0; i< this.data.length; i++) {
      output.data[i] = Object.assign({}, this.data[i]);
    }
    output.meta = Object.assign({}, this.meta);
    return output;
  }
}

const apiUrl = dataServiceConfig.url;
export default class ControllerService {
  private config: any;

  constructor(config) {
    if (config == null) {
      config = {};
    }
    this.config = config;
  }

  // isPermitted(installId): Promise<Boolean> {
  //   let service = this;
  //   const {t} = useTranslation();
  //   return new Promise((resolve, reject) => {
  //     let query = new Query({
  //       filter: {
  //         query: installId,
  //         page: 0,
  //         pageSize: -1
  //       },
  //       sort: {
  //         by: '',
  //         order: 'asc'
  //       }
  //     });
  //     //todo: write dedicated route for this permission check
  //     //      list transfers a more data than needed, but will work
  //     //      all real permissions are handled server side, but this is
  //     //      for the purpose of graceful user experience.
  //     // query.filter.query = installId;
  //     service.list(query).then(commsUnitsList => {
  //       //this is a query, there may be unexpeced results.
  //       // e.g. searching for BRI85 will also return BRI857, BRI858
  //       let found = false;
  //       for (let i = 0; i < commsUnitsList.data.length; i++) {
  //         if (commsUnitsList.data[i].install_id === installId) {
  //           found = true;
  //           break;
  //         }
  //       }
  //       resolve(found);
  //     }).catch(err => {
  //       reject(err);
  //     })
  //   });
  // }


  //
  // static register = function () {
  //   let params = {
  //     email_address: emailAddress,
  //     first_name: firstName,
  //     last_name: lastName,
  //     mobile_number: mobileNumber,
  //     country_code: countryCode,
  //     password: password,
  //     password_confirm: passwordConfirm,
  //     recaptcha_token: reCaptchaToken,
  //     agreed_to_terms: agreedToTerms
  //   };
  //   if (typeof inviteToken == 'string' && inviteToken !== ''){
  //     params['invite_token'] = inviteToken;
  //   }
  //   return new Promise((resolve, reject) => {
  //     const requestOptions = {
  //       method: 'POST',
  //       headers: {'Content-Type': 'application/json'},
  //       credentials: 'include',
  //       mode: 'cors',
  //       body: JSON.stringify(params)
  //     };
  //     return fetch(`${apiUrl}/register`, requestOptions)
  //       .then(res => res.json())
  //       .then(response => {
  //         // eslint-disable-next-line no-prototype-builtins
  //         if (response.hasOwnProperty('error')
  //           && response.error != null
  //         ) {
  //           reject(response.error);
  //           return;
  //         }
  //         CommsUnitService.handleSuccessfulNewAuthToken(response, emailAddress)
  //           .then((authResponse) => {
  //             resolve(authResponse);
  //           })
  //           .catch(error => {
  //             reject(error);
  //           })
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // };
  //
  // static get = function (): Promise<CommsUnit> {
  //   return new Promise((resolve, reject) => {
  //     // const userSelflocalStorageKey = 'user_self'
  //     // let userSelfData = window.localStorage.getItem(userSelflocalStorageKey);
  //     // if (userSelfData != null  ) {
  //     //   let userSelf = new User(JSON.parse(userSelfData));
  //     //   let sessionUserData = UserService.getCurrentSession();
  //     //   if (sessionUserData.email !== userSelf.email){
  //     //     window.localStorage.setItem(userSelflocalStorageKey, null);
  //     //   } else {
  //     //     resolve(userSelf);
  //     //     return;
  //     //   }
  //     // }
  //     let dataUrl = `${apiUrl}/user/self`;
  //     fetch(dataUrl, {
  //       method: 'get',
  //       credentials: 'include',
  //     })
  //       .then((response) => {
  //         switch (response.status) {
  //           case 200:
  //             return response.json();
  //           case 403:
  //             throw new Error(t('Unauthorised to Access Resource');
  //           default:
  //             throw new Error(t('Could not fetch current user details');
  //         }
  //       })
  //       .then(function (userSelfResponse) {
  //         if (userSelfResponse.hasOwnProperty('error') && userSelfResponse.error != null) {
  //           reject(new Error(t(userSelfResponse.error)));
  //         } else {
  //           //window.localStorage.setItem(userSelflocalStorageKey, JSON.stringify(userSelfResponse.user));
  //           resolve(new User(userSelfResponse.user));
  //         }
  //       })
  //       .catch((error) => {
  //         reject(new Error(t(error)));
  //       })
  //
  //   });
  // }
  //
  // static release = function () {
  //   let params = {
  //     email_address: emailAddress,
  //     first_name: firstName,
  //     last_name: lastName,
  //     mobile_number: mobileNumber,
  //     country_code: countryCode,
  //     password: password,
  //     password_confirm: passwordConfirm,
  //     recaptcha_token: reCaptchaToken,
  //     agreed_to_terms: agreedToTerms
  //   };
  //   if (typeof inviteToken == 'string' && inviteToken !== ''){
  //     params['invite_token'] = inviteToken;
  //   }
  //   return new Promise((resolve, reject) => {
  //     const requestOptions = {
  //       method: 'POST',
  //       headers: {'Content-Type': 'application/json'},
  //       credentials: 'include',
  //       mode: 'cors',
  //       body: JSON.stringify(params)
  //     };
  //     return fetch(`${apiUrl}/register`, requestOptions)
  //       .then(res => res.json())
  //       .then(response => {
  //         // eslint-disable-next-line no-prototype-builtins
  //         if (response.hasOwnProperty('error')
  //           && response.error != null
  //         ) {
  //           reject(response.error);
  //           return;
  //         }
  //         CommsUnitService.handleSuccessfulNewAuthToken(response, emailAddress)
  //           .then((authResponse) => {
  //             resolve(authResponse);
  //           })
  //           .catch(error => {
  //             reject(error);
  //           })
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // };

  // total(query: DataQuery): Promise<any> {
  // //total(installId, startDate, endDate, controllerIds = ['comms_unit_input_1']): Promise<Number> {
  //
  //   let params = {
  //     "filter[install_id]": query.filter.installId,
  //     //"filter[from]": moment(startDate).format(),
  //     //"filter[to]": moment(endDate).format(),
  //     "filter[from]": formatISO(query.filter.from),
  //     "filter[to]": formatISO(query.filter.to),
  //     "filter[optimiser_ids]": query.filter.controllerIds,
  //   };
  //   let service = this;
  //   let queryString = Object.keys(params).map(function (key) {
  //     return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
  //   }).join('&');
  //
  //   //const {t} = useTranslation();
  //   const t = (phrase) => {return phrase};
  //   return new Promise((resolve, reject) => {
  //
  //     let dataUrl = dataServiceConfig.url + '/comms-unit/total?' + queryString;
  //
  //     fetch(dataUrl, {
  //       method: 'get',
  //       credentials: 'include',
  //     })
  //         .then(service.config.afterFetch)
  //         .then((response) => {
  //           switch (response.status) {
  //             case 200:
  //               return response.json();
  //             case 403:
  //               throw new Error(t(phrases.Errors.Unauthorized));
  //             default:
  //               throw new Error(t('Could not fetch commsunits totals'));
  //           }
  //         })
  //         .then((response) => {
  //           console.log(JSON.stringify(response) + " - totals response");
  //           if (response?.total === undefined || response?.units === undefined) {
  //             reject(new Error(t('Malformed Response')));
  //             return;
  //           }
  //
  //           console.log(JSON.stringify(response) + " - totals response - resolve");
  //           resolve(response);
  //         })
  //         .catch((error) => {
  //           reject(new Error(t(error)));
  //         })
  //   });
  //
  // }

  list(query: DataQuery): Promise<ControllerList> {

    let dataUrl = `${apiUrl}/controller/list`;
    let params = {
      filter: {
        install_id: query.filter.installId,
        from: formatRFC3339(query.filter.from),
        to: formatRFC3339(query.filter.to),
        page: 0,
        page_size: 100,
      },
      // 'filter[install_id]': query.filter.installId,
      // 'filter[from]': formatRFC3339(query.filter.from),
      // 'filter[to]':   formatRFC3339(query.filter.to),
      // 'limit': 100,
      // 'page': 0 //getpage here
    };
    // if (searchQuery !== ''){
    //   params['filter[search_query]'] = query.filter.;
    // }
    // var queryString = Object.keys(params).map(function(key) {
    //   return key + '=' + params[key]
    // }).join('&');


    let service = this;
    //const {t} = useTranslation();
    const t = (phrase) => {return phrase;}
    return new Promise((resolve, reject) => {
      fetch(dataUrl, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(params)
      })
          .then(service.config.afterFetch)
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              case 404:
                throw new Error(t('No controllers found'));
              case 403:
                throw new Error(t('Unauthorised to Access Resource'));
              default:
                throw new Error(t(`Got response: ${response.status}: Could not fetch controllers`));
            }
          })
          .then(function (response) {
            // let expectedResponseFields = [
            //   'error',
            //   'meta',
            //   'comms_units'
            // ];
            //
            // let isValidResponse = !expectedResponseFields.map((field) => {
            //   return Object.prototype.hasOwnProperty.call(response, field)
            // }).includes(false);
            //
            // if (!isValidResponse){
            //   reject(phrases.Errors.UnExpectedResponse);
            // }
            if (response.hasOwnProperty('error') && response.error != null) {
              reject(new Error(t(response.error)));
            } else {
              //validate response
              if (!Array.isArray(response.data)) {
                reject(new Error(t(phrases.Errors.UnExpectedResponse)));
              }
              let output = new ControllerList();
              output.data = [];
              for (let i = 0; i < response.data.length; i++) {
                output.data[i] = new Controller(response.data[i])
              }
              output.meta = response.meta;
              resolve(output);
            }
          })
          .catch((error) => {
            reject(new Error(t(error)));
          })

    });

  }
}

export {ControllerService, Controller, ControllerList};
