import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    moduleHealthRating: {
        byQuery: {}
    },
    totalEnergy: {
        byQuery: {}
    },
};

export const slice = createSlice({
    name: 'deviceStatistics',
    initialState,
    reducers: {
        getModuleHealthRatings(state, action) {
            state.moduleHealthRating.byQuery[action.payload.query] = action.payload.result;
        },
        getTotalEnergy(state, action) {
            state.totalEnergy.byQuery[action.payload.query] = action.payload.result;
        },
    }
});

export const {reducer} = slice;