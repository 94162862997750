import {createSlice, current } from '@reduxjs/toolkit';
import {objFromArray} from "../utils/obj-from-array";
//import { current } from 'immer';
const initialState = {
    notifications: {
        byQuery: {},
        byId: {},
    }
};

export const slice = createSlice({
    name: 'userNotification',
    initialState,
    reducers: {
        listUserNotifications(state, action) {
            state.notifications.byQuery = {...state.notifications.byQuery, [action.payload.query]: action.payload.result}
            for (let notification of action.payload.result.data) {
                state.notifications.byId[notification.id] = notification;
            }
        },
        markAsReadUserNotifications(state, action) {
            for (let notification of action.payload.data) {
                state.notifications.byId[notification.id] = notification;
            }
            //todo: create pidgeon hole cache for queries
            state.notifications.byQuery = [];
        },
        markAsClickedUserNotifications(state, action) {
            for (let notification of action.payload.data) {
                state.notifications.byId[notification.id] = notification;
            }
            //todo: create pidgeon hole cache for queries
            state.notifications.byQuery = [];
        },
    }
});

export const {reducer} = slice;