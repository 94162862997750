/**
 * Copyright © Tony Schirmer All rights reserved.
 * See LICENSE.txt for license details.
 */
import {dataServiceConfig} from '../../config';
import {format, formatRFC3339, parseISO} from 'date-fns';
//import {useTranslation} from "react-i18next";
import phrases from "../phrases"
import NotFoundError from "../errors/NotFoundError";
class UserNotification {


  id: null;
  type: string;
  message: string;
  read_at?: Date;
  created_at: Date;
  push_to_device: boolean;
  clicked_at?: Date;
  additional_data?: string;

  constructor(obj) {
    obj && Object.assign(this, obj);

    if (!obj?.read_at) {
      this.read_at = null;
    } else {
      this.read_at = parseISO(obj.read_at);
    }

    if (!obj?.created_at) {
      this.created_at = null;
    } else {
      this.created_at = parseISO(obj.created_at);
    }

    if (!obj?.clicked_at) {
      this.clicked_at = null;
    } else {
      this.clicked_at = parseISO(obj.clicked_at);
    }
  }
  ToNativeJsObject() : any {
    let nativeJsObj: any = {};
    Object.assign(nativeJsObj, this);
    if (nativeJsObj?.clicked_at !== null){
      nativeJsObj.clicked_at = formatRFC3339(nativeJsObj.clicked_at);
    }
    if (nativeJsObj?.created_at !== null){
      nativeJsObj.created_at = formatRFC3339(nativeJsObj.created_at);
    }
    if (nativeJsObj?.read_at !== null){
      nativeJsObj.read_at = formatRFC3339(nativeJsObj.read_at);
    }
    return nativeJsObj;
  }
}

class UserNotificationList {
  data: Array<UserNotification>;
  meta: {
    total: 0,
    page: 0,
    page_size: 0
  };

  ToNativeJsObject() : any {
    let output = {
      data: [],
      meta: {
        total: 0,
        page: 0,
        page_size: 0
      }
    };
    for (let i=0; i< this.data.length; i++) {
      output.data[i] = this.data[i].ToNativeJsObject();
    }
    output.meta = Object.assign({}, this.meta);
    return output;
  }
}

const apiUrl = dataServiceConfig.url;
export default class UserNotificationService {
  private config: any;

  constructor(config) {
    if (config == null) {
      config = {};
    }
    this.config = config;
  }


  list(query): Promise<UserNotificationList> {

    let service = this;
    const t = (phrase) => { return phrase};
    let dataUrl = `${apiUrl}/user_notification/list`;
    return new Promise((resolve, reject) => {
      fetch(dataUrl, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(query)
      })
          .then(service.config.afterFetch)
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              case 403:
                throw new Error(t(phrases.Errors.Unauthorized));
              default:
                throw new Error(t('Could not fetch user notifications'));
            }
          })
          .then(function (response) {

            if (response.hasOwnProperty('error') && response.error != null) {
              reject(new Error(t(response.error)));
            } else {
              //validate response
              if (!Array.isArray(response.data)) {
                reject(new Error(t(phrases.Errors.UnExpectedResponse)));
              }
              let output = new UserNotificationList();
              output.data = [];
              for (let i = 0; i < response.data.length; i++) {
                output.data[i] = new UserNotification(response.data[i]);
              }
              output.meta = response.meta;
              resolve(output);
            }
          })
          .catch((error) => {
            reject(new Error(t(error)));
          })

    });


  }

  markAsRead(userNotificationIds) : Promise<UserNotificationList> {

    let service = this;
    //const {t} = useTranslation();
    const t = (phrase) => { return phrase};
    return new Promise((resolve, reject) => {

      let dataUrl = `${apiUrl}/user_notification/mark_as_read`;
      fetch(dataUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          ids: userNotificationIds,
        })
      })
          .then(service.config.afterFetch)
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              case 403:
                throw new Error(t(phrases.Errors.Unauthorized));
              default:
                throw new Error(t('Could not fetch user notifications'));
            }
          })
          .then(function (response) {

            if (response.hasOwnProperty('error') && response.error != null) {
              reject(new Error(t(response.error)));
            } else {
              //validate response
              if (!Array.isArray(response.data)) {
                reject(new Error(t(phrases.Errors.UnExpectedResponse)));
              }
              let output = new UserNotificationList();
              output.data = [];
              for (let i = 0; i < response.data.length; i++) {
                output.data[i] = new UserNotification(response.data[i]);
              }
              output.meta = response.meta;
              resolve(output);
            }
          })
          .catch((error) => {
            reject(new Error(t(error)));
          })
    });
  }

  markAsClicked(userNotificationIds): Promise<UserNotificationList> {

    let service = this;
    //const {t} = useTranslation();
    const t = (phrase) => { return phrase};
    return new Promise((resolve, reject) => {

      let dataUrl = `${apiUrl}/user_notification/mark_as_clicked`;
      fetch(dataUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          ids: userNotificationIds,
        })
      })
          .then(service.config.afterFetch)
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              case 403:
                throw new Error(t(phrases.Errors.Unauthorized));
              default:
                throw new Error(t('Could not fetch user notifications'));
            }
          })
          .then(function (response) {

            if (response.hasOwnProperty('error') && response.error != null) {
              reject(new Error(t(response.error)));
            } else {
              //validate response
              if (!Array.isArray(response.data)) {
                reject(new Error(t(phrases.Errors.UnExpectedResponse)));
              }
              let output = new UserNotificationList();
              output.data = [];
              for (let i = 0; i < response.data.length; i++) {
                output.data[i] = new UserNotification(response.data[i]);
              }
              output.meta = response.meta;
              resolve(output);
            }
          })
          .catch((error) => {
            reject(new Error(t(error)));
          })
    });
  }
}

export {UserNotificationService, UserNotification, UserNotificationList};
