/**
 * Copyright © Tony Schirmer All rights reserved.
 * See LICENSE.txt for license details.
 */
import {dataServiceConfig} from '../../config';
import {useTranslation} from "react-i18next";
import phrases from "../phrases"
import Phrases from "../phrases";
import {DeviceLocation, DeviceLocationList} from "../DeviceLocationService/DeviceLocationService";

const DeviceTypes =  {
  CommsUnit: 'comms_unit',
  Controller: 'controller',
  Drone: 'drone'
}

class DeviceRegistration {


  id: '';
  install_id: '';
  serial_number: '';
  device_type: '';
  manufactured_at: null;// Date(Date.now());
  claimed_at: null; //new Date(0);
  claimed_by_user_id: '';
  claimed_by_user_data_flat: '';
  allocated_to_location_id: '';

  constructor(obj) {
    obj && Object.assign(this, obj);
  }

  getDeviceTypeText(deviceType) {
    switch(deviceType){
      case DeviceTypes.CommsUnit:
        return Phrases.Definitions.CommsUnit;
      case DeviceTypes.Controller:
        return Phrases.Definitions.Controller;
      case DeviceTypes.Drone:
        return Phrases.Definitions.Drone;
    }
    return '';
  }


  ToNativeJsObject() : any {
    return Object.assign({}, this);
  }

}

// eslint-disable-next-line
class DeviceRegistrationList {
  data: Array<DeviceRegistration>;
  meta: {
    total: 0,
    page: 0,
    page_size: 0
  };

  ToNativeJsObject() : any {
    let output = {
      data: [],
      meta: {
        total: 0,
        page: 0,
        page_size: 0
      }
    };
    for (let i=0; i< this.data.length; i++) {
      output.data[i] = Object.assign({}, this.data[i]);
    }
    output.meta = Object.assign({}, this.meta);
    return output;
  }
}

const apiUrl = dataServiceConfig.url;
export default class DeviceRegistrationService {
  private config: any;

  constructor(config) {
    if (config == null) {
      config = {};
    }
    this.config = config;
  }


  get(id): Promise<DeviceRegistration> {

    let service = this;
    const t = (phrase) => { return phrase};
    let dataUrl = `${apiUrl}/device_registration/get`;
    return new Promise((resolve, reject) => {
      fetch(dataUrl, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({id: id})
      })
          .then(service.config.afterFetch)
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              case 403:
                throw new Error(t(phrases.Errors.Unauthorized));
              default:
                throw new Error(t('Could not fetch device registration'));
            }
          })
          .then(function (response) {
            let expectedResponseFields = [
              'error',
              'data'
            ];

            let isValidResponse = !expectedResponseFields.map((field) => {
              return Object.prototype.hasOwnProperty.call(response, field)
            }).includes(false);

            if (!isValidResponse){
              reject(phrases.Errors.UnExpectedResponse);
            }
            if (response.hasOwnProperty('error') && response.error != null) {
              reject(new Error(t(response.error)));
            } else {
              //validate response
              resolve(new DeviceRegistration(response?.data));
            }
          })
          .catch((error) => {
            reject(new Error(t(error)));
          })

    });


  }

  list(query): Promise<DeviceRegistrationList> {

    let service = this;
    const t = (phrase) => { return phrase};
    let dataUrl = `${apiUrl}/device_registration/list`;
    return new Promise((resolve, reject) => {
      fetch(dataUrl, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(query)
      })
          .then(service.config.afterFetch)
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              case 403:
                throw new Error(t(phrases.Errors.Unauthorized));
              default:
                throw new Error(t('Could not fetch device registrations'));
            }
          })
          .then(function (response) {
            // let expectedResponseFields = [
            //   'error',
            //   'meta',
            //   'data'
            // ];
            //
            // let isValidResponse = !expectedResponseFields.map((field) => {
            //   return Object.prototype.hasOwnProperty.call(response, field)
            // }).includes(false);
            //
            // if (!isValidResponse){
            //   reject(phrases.Errors.UnExpectedResponse);
            // }
            if (response.hasOwnProperty('error') && response.error != null) {
              reject(new Error(t(response.error)));
            } else {
              //validate response
              if (!Array.isArray(response.data)) {
                reject(new Error(t(phrases.Errors.UnExpectedResponse)));
              }
              let output = new DeviceRegistrationList();
              output.data = [];
              for (let i = 0; i < response.data.length; i++) {
                output.data[i] = new DeviceRegistration(response.data[i])
              }
              output.meta = response.meta;
              resolve(output);
            }
          })
          .catch((error) => {
            reject(new Error(t(error)));
          })

    });


  }

  //
  // static register = function () {
  //   let params = {
  //     email_address: emailAddress,
  //     first_name: firstName,
  //     last_name: lastName,
  //     mobile_number: mobileNumber,
  //     country_code: countryCode,
  //     password: password,
  //     password_confirm: passwordConfirm,
  //     recaptcha_token: reCaptchaToken,
  //     agreed_to_terms: agreedToTerms
  //   };
  //   if (typeof inviteToken == 'string' && inviteToken !== ''){
  //     params['invite_token'] = inviteToken;
  //   }
  //   return new Promise((resolve, reject) => {
  //     const requestOptions = {
  //       method: 'POST',
  //       headers: {'Content-Type': 'application/json'},
  //       credentials: 'include',
  //       mode: 'cors',
  //       body: JSON.stringify(params)
  //     };
  //     return fetch(`${apiUrl}/register`, requestOptions)
  //       .then(res => res.json())
  //       .then(response => {
  //         // eslint-disable-next-line no-prototype-builtins
  //         if (response.hasOwnProperty('error')
  //           && response.error != null
  //         ) {
  //           reject(response.error);
  //           return;
  //         }
  //         CommsUnitService.handleSuccessfulNewAuthToken(response, emailAddress)
  //           .then((authResponse) => {
  //             resolve(authResponse);
  //           })
  //           .catch(error => {
  //             reject(error);
  //           })
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // };
  //
  // static get = function (): Promise<DeviceRegistration> {
  //   return new Promise((resolve, reject) => {
  //     // const userSelflocalStorageKey = 'user_self'
  //     // let userSelfData = window.localStorage.getItem(userSelflocalStorageKey);
  //     // if (userSelfData != null  ) {
  //     //   let userSelf = new User(JSON.parse(userSelfData));
  //     //   let sessionUserData = UserService.getCurrentSession();
  //     //   if (sessionUserData.email !== userSelf.email){
  //     //     window.localStorage.setItem(userSelflocalStorageKey, null);
  //     //   } else {
  //     //     resolve(userSelf);
  //     //     return;
  //     //   }
  //     // }
  //     let dataUrl = `${apiUrl}/device-registration/get`;
  //     fetch(dataUrl, {
  //       method: 'get',
  //       credentials: 'include',
  //     })
  //       .then((response) => {
  //         switch (response.status) {
  //           case 200:
  //             return response.json();
  //           case 403:
  //             throw new Error('Unauthorised to Access Resource');
  //           default:
  //             throw new Error('Could not fetch current user details');
  //         }
  //       })
  //       .then(function (userSelfResponse) {
  //         if (userSelfResponse.hasOwnProperty('error') && userSelfResponse.error != null) {
  //           reject(new Error(userSelfResponse.error));
  //         } else {
  //           //window.localStorage.setItem(userSelflocalStorageKey, JSON.stringify(userSelfResponse.user));
  //           resolve(new User(userSelfResponse.user));
  //         }
  //       })
  //       .catch((error) => {
  //         reject(new Error(error));
  //       })
  //
  //   });
  // }
  //
  // static release = function () {
  //   let params = {
  //     email_address: emailAddress,
  //     first_name: firstName,
  //     last_name: lastName,
  //     mobile_number: mobileNumber,
  //     country_code: countryCode,
  //     password: password,
  //     password_confirm: passwordConfirm,
  //     recaptcha_token: reCaptchaToken,
  //     agreed_to_terms: agreedToTerms
  //   };
  //   if (typeof inviteToken == 'string' && inviteToken !== ''){
  //     params['invite_token'] = inviteToken;
  //   }
  //   return new Promise((resolve, reject) => {
  //     const requestOptions = {
  //       method: 'POST',
  //       headers: {'Content-Type': 'application/json'},
  //       credentials: 'include',
  //       mode: 'cors',
  //       body: JSON.stringify(params)
  //     };
  //     return fetch(`${apiUrl}/register`, requestOptions)
  //       .then(res => res.json())
  //       .then(response => {
  //         // eslint-disable-next-line no-prototype-builtins
  //         if (response.hasOwnProperty('error')
  //           && response.error != null
  //         ) {
  //           reject(response.error);
  //           return;
  //         }
  //         CommsUnitService.handleSuccessfulNewAuthToken(response, emailAddress)
  //           .then((authResponse) => {
  //             resolve(authResponse);
  //           })
  //           .catch(error => {
  //             reject(error);
  //           })
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // };

  claim(claimKey, locationId, tags): Promise<boolean> {
    let service = this;
    const t = (phrase) => {return phrase}; //useTranslation();
    let dataUrl = `${apiUrl}/device-registration/claim`;
    return new Promise((resolve, reject) => {
      fetch(dataUrl, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({claim_key: claimKey, location_id: locationId})
      })
          .then(service.config.afterFetch)
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              case 403:
                throw new Error(t(phrases.Errors.Unauthorized));
              default:
                throw new Error(t(phrases.Errors.ServerUnavailable));
            }
          })
          .then(function (response) {

            if (Object.prototype.hasOwnProperty.call(response, 'error') && response.error != null) {
              reject(new Error(t(phrases.Errors.ClaimKeyInvalid)));
              return;
            } else {
              //validate response
              if (!Object.prototype.hasOwnProperty.call(response, 'data')) {
                reject(new Error(t(phrases.Errors.ClaimKeyInvalid)));
                return;
              }
              resolve(response.data);
            }
          })
          .catch((error) => {
            reject(new Error(t(error)));
          })

    });
  }

  validate(claimKey): Promise<boolean> {
    let service = this;
    const t = (phrase) => {return phrase}; //useTranslation();
    let dataUrl = `${apiUrl}/device-registration/validate`;
    return new Promise((resolve, reject) => {
      fetch(dataUrl, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({claim_key: claimKey})
      })
          .then(service.config.afterFetch)
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              case 400:
                return response.json();
              case 403:
                throw new Error(t(phrases.Errors.Unauthorized));
              default:
                throw new Error(t(phrases.Errors.ServerUnavailable));
            }
          })
          .then(function (response) {

            if (Object.prototype.hasOwnProperty.call(response, 'error') && response.error != null) {
              reject(new Error(t(phrases.Errors.ClaimKeyInvalid)));
              return;
            } else {
              //validate response
              if (!Object.prototype.hasOwnProperty.call(response, 'is_valid')) {
                reject(new Error(t(phrases.Errors.ClaimKeyInvalid)));
                return;
              }
              resolve(response.is_valid);
            }
          })
          .catch((error) => {
            reject(new Error(t(error)));
          })

    });

  }
}

export {DeviceRegistrationService};
