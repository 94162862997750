/**
 * Copyright © Tony Schirmer All rights reserved.
 * See LICENSE.txt for license details.
 */
import {dataServiceConfig} from '../../config';
//import {useTranslation} from "react-i18next";
import phrases from "../phrases"
import NotFoundError from "../errors/NotFoundError";
class DeviceLocationMeta {

  id: null;
  account_id: string;
  location_id: string;
  day: string;
  day_timestamp: string;
  device_count: any;
  expected_device_count: any;
  device_errors: any;
  indexed_device_data: any;
  created_at: Date;
  updated_at: Date;

  constructor(obj) {
    obj && Object.assign(this, obj);
  }
  ToNativeJsObject() : any {
    return Object.assign({}, this);
  }
}

class DeviceLocationMetaList {
  data: Array<DeviceLocationMeta>;
  meta: {
    total: 0,
    page: 0,
    page_size: 0
  };

  ToNativeJsObject() : any {
    let output = {
      data: [],
      meta: {
        total: 0,
        page: 0,
        page_size: 0
      }
    };
    for (let i=0; i< this.data.length; i++) {
      output.data[i] = Object.assign({}, this.data[i]);
    }
    output.meta = Object.assign({}, this.meta);
    return output;
  }
}

const apiUrl = dataServiceConfig.url;
export default class DeviceLocationMetaService {
  private config: any;

  constructor(config) {
    if (config == null) {
      config = {};
    }
    this.config = config;
  }


  //get
  // date in the format "20221204"
  get(deviceLocationId, date): Promise<DeviceLocationMeta> {
    let service = this;
    const t = (phrase) => { return phrase}; // useTranslation();
    return new Promise((resolve, reject) => {
      if (! (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(deviceLocationId))){
        reject(new Error("id is not a uuid"));
      }
      if (!(/^[0-9]{8}$/i.test(date))){
        reject(new Error("date must be in the format \"20221204\""));
      }
      let dataUrl = `${apiUrl}/device_location_meta/get`;
      fetch(dataUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          location_id: deviceLocationId,
          date: date
        })
      })
          .then(service.config.afterFetch)
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              case 403:
                throw new Error(t(phrases.Errors.Unauthorized));
              case 404:
                reject(new NotFoundError(t('Could not get device location meta')));
                break;
              default:
                reject( new Error(t('Could not get device location meta')));
                break;
            }})
          .then(result => {
            resolve(new DeviceLocationMeta(result?.data));
          })
          .catch((error) => {
            reject(error);
          });
    });
  }


}

export {DeviceLocationMetaService, DeviceLocationMeta, DeviceLocationMetaList};
